import React from 'react';
import PageHeader from './global-components/page-header';
import Error from './section-components/error';
import CallToActionV1 from './section-components/call-to-action-v1';
import NavbarMain from '../prod/components/NavbarMain';
import FooterMain from '../prod/components/FooterMain';

const ErrorPage = () => {
    return <div>
        <NavbarMain />
        <PageHeader headertitle="404 Page" />
        <Error />
        <CallToActionV1 />
        <FooterMain />
    </div>
}

export default ErrorPage

