import React, { useState, useEffect } from "react";
// import Navbar from './global-components/navbar-v2';
import PageHeader from "./global-components/page-header";
import ShogGrid from "./shop-components/shop-grid-v1";
import CallToActionV1 from "./section-components/call-to-action-v1";
// import Footer from './global-components/footer';
import NavbarMain from "../prod/components/NavbarMain";
import FooterMain from "../prod/components/FooterMain";
import { Spin } from "antd";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setGetData } from "../redux/feature/getDataSlice";
import "../css/app.css";
import "antd/dist/antd.css";

const ShopGrid_V1 = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    axios
    .get("https://hyonlxjq70.execute-api.us-west-2.amazonaws.com/dev/items/")
    .then((response) => {
      dispatch(setGetData(response.data.Items))
    })
    .catch((error) => {
      console.log(error);
    });

    const timer = setTimeout(() => setLoading(false), 2000); // 2 seconds

    return () => clearTimeout(timer); // Clear the timer if the component is unmounted
  }, []); // Empty dependency array ensures this runs once on mount

  if (loading) {
    return (
      <div className="center_component">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      <NavbarMain />
      {/* <PageHeader headertitle="All Presales" /> */}
      <ShogGrid />
      <CallToActionV1 />
      <FooterMain />
    </div>
  );
};

export default ShopGrid_V1;
