import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getData: [],
    surrey: 0,
    vancouver: 0,
    richmond: 0,
    coquitlam: 0,
    portmoody: 0,
    langley: 0,
    abbotsford: 0,
    mapleridge: 0
  };

const getDataSlice = createSlice({
    name: "getData",
    initialState,
    reducers: {
        setGetData: (state, action) => {
            state.getData = action.payload;
        }, 
        setSurrey: (state, action) => {
            state.surrey = action.payload;
        },
        setVancouver: (state, action) => {
            state.vancouver = action.payload;
        },
        setRichmond: (state, action) => {
            state.richmond = action.payload;
        },
        setCoquitlam: (state, action) => {
            state.coquitlam = action.payload;
        },
        setPortMoody: (state, action) => {
            state.portmoody = action.payload;
        },
        setLangley: (state, action) => {
            state.langley = action.payload;
        },
        setAbbotsFord: (state, action) => {
            state.abbotsford = action.payload;
        },
        setMapleRidge: (state, action) => {
            state.mapleridge = action.payload;
        }
    }
});

export const {
    setGetData,
    setSurrey,
    setVancouver,
    setRichmond,
    setCoquitlam,
    setPortMoody,
    setLangley,
    setAbbotsFord,
    setMapleRidge
} = getDataSlice.actions;

export default getDataSlice.reducer;