import React from 'react';
import PageHeader from './global-components/page-header';
import ProductDetails from './shop-components/shop-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import NavbarMain from '../prod/components/NavbarMain';
import FooterMain from '../prod/components/FooterMain';
import ProductSliderMain from '../prod/components/product/ProductSliderMain';

const Product_Details = () => {
    return <div>
        <NavbarMain />
        <PageHeader headertitle="Property Details" customclass="mb-0" />
        {/* <ProductSlider /> */}
        <ProductSliderMain />
        <ProductDetails />
        <CallToActionV1 />
        <FooterMain />
    </div>
}

export default Product_Details

