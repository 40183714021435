import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class FeaturesV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    let customClass = this.props.customClass ? this.props.customClass : "";

    return (
      <div className={customClass}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Our Services
                </h6>
                <h1 className="section-title">Our Main Focus</h1>
              </div>
            </div>
          </div>
          <div className="row ltn__custom-gutter--- justify-content-center go-top">
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <img src={"assets/img/icons/icon-img/23.png"} alt="#" />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="/service-details">Customer-Centric Approach</Link>
                  </h3>
				
                  <p style={{paddingTop: 18}}>
                    Our primary focus is always you - the customer. We strive to
                    understand your unique needs and desires when it comes to
                    finding the perfect pre-sale condo. Our dedicated team
                    prioritizes your comfort and convenience, providing
                    personalized service that is second to none.
                  </p>
                  {/* <Link className="ltn__service-btn" to="/shop-grid">
                    Find A Home <i className="flaticon-right-arrow" />
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <img src={"assets/img/icons/icon-img/21.png"} alt="#" />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="/service-details">Quality & Innovation</Link>
                  </h3>
                  <p>
                    We work only with developers who exhibit a strong commitment
                    to quality, innovation, and sustainability. We ensure that
                    every pre-sale condo we present to you not only matches, but
                    exceeds the industry's highest standards. From modern
                    architectural designs to cutting-edge amenities we guarantee
                    that our properties stand out in the current market.
                  </p>
                  {/* <Link className="ltn__service-btn" to="/service-details">
                    Find A Home <i className="flaticon-right-arrow" />
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                <div className="ltn__feature-icon">
                  <img src={"assets/img/icons/icon-img/22.png"} alt="#" />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="/service-details">
                      Honest Communication
                    </Link>
                  </h3>
				
                  <p style={{paddingTop: 22}}>
                    We believe in clear, honest, and frequent communication.
                    From the initial consultation to the final closing, we
                    ensure our clients are well-informed at every step. We
                    provide accurate, up-to-date information about the status of
                    the development, pricing, and any changes that may impact
                    the buyer's decision.
                  </p>
                  {/* <Link className="ltn__service-btn" to="/service-details">
                    Find A Home <i className="flaticon-right-arrow" />
                  </Link> */}
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/23.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3><Link to="/service-details">Sell a home</Link></h3>
			            <p>over 1 million+ homes for sale available on the website, we can match you with a house you will want to call home.</p>
			            <Link className="ltn__service-btn" to="/service-details">Find A Home <i className="flaticon-right-arrow" /></Link>
			          </div>
			        </div>
			      </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default FeaturesV1;
