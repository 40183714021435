import React from "react";
import ReactDOM from "react-dom";

import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Switch,
} from "react-router-dom";
import HomeV1 from "./components/home-v1";
import HomeV2 from "./components/home-v2";
import HomeV3 from "./components/home-v3";
import HomeV4 from "./components/home-v4";
import HomeV5 from "./components/home-v5";
import HomeV6 from "./components/home-v6";
import HomeV7 from "./components/home-v7";
import HomeV8 from "./components/home-v8";
import HomeV9 from "./components/home-v9";
import HomeV10 from "./components/home-v11";
// import HomeMain from "./prod/screens/HomeMain";

import About from "./components/about";
import Service from "./components/service";
import ServiceDetails from "./components/service-details";
import Portfolio from "./components/portfolio";
import PortfolioV2 from "./components/portfolio-v2";
import PortfolioDetails from "./components/portfolio-details";
import Team from "./components/team";
import TeamDetails from "./components/team-details";
import Faq from "./components/faq";
import ComingSoon from "./components/coming-soon";
import Error from "./components/404";
import Location from "./components/location";

import Shop from "./components/shop";
import ShopGrid from "./components/shop-grid";
import ProdductDetails from "./components/product-details";
import ShopLeftSidebar from "./components/shop-left-sidebar";
import ShopRightSidebar from "./components/shop-right-sidebar";

import BlogGrid from "./components/blog-grid";
import BlogLeftSidebar from "./components/blog-left-sidebar";
import BlogRightSidebar from "./components/blog-right-sidebar";
import Blog from "./components/blog";

import BlogDetails from "./components/blog-details";
// import Contact from './components/contact';
import ContactMain from "./prod/screens/ContactMain";
import Cart from "./components/cart";
import Checkout from "./components/checkout";
import MyAccount from "./components/my-account";
import Login from "./components/login";
import Register from "./components/register";
// import PreRegister from "./prod/screens/PreRegister";
import AddListing from "./components/add-listing";
import Wishlist from "./components/wishlist";
import OrderTracking from "./components/order-tracking";
import History from "./components/history";
// import NavbarMain from "./prod/components/NavbarMain";
// import FooterMain from "./prod/components/FooterMain";
// import 'bootstrap/dist/css/bootstrap.min.css';

//redux-persist
import store from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import Admin from "./prod/screens/Admin";

//Amplify
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const persistor = persistStore(store);

const Root = () => {
  return (
    <div>
      <HashRouter basename="/">
        <div>
          <Switch>
            <Route exact path="/" component={HomeV2} />
            <Route path="/home-v2" component={Error} />
            <Route path="/home-v3" component={Error} />
            <Route path="/home-v4" component={Error} />
            <Route path="/home-v5" component={Error} />
            <Route path="/home-v6" component={Error} />
            <Route path="/home-v7" component={Error} />
            <Route path="/home-v8" component={Error} />
            <Route path="/home-v9" component={Error} />
            <Route path="/home-v10" component={Error} />

            <Route path="/about" component={About} />

            {/* <Route path="/service" component={Service} />
            <Route path="/service-details" component={ServiceDetails} />
            <Route path="/portfolio" component={Portfolio} />
            <Route path="/portfolio-v2" component={PortfolioV2} />
            <Route path="/portfolio-details" component={PortfolioDetails} />
            <Route path="/team" component={Team} /> */}
            <Route path="/service" component={Error} />
            <Route path="/service-details" component={Error} />
            <Route path="/portfolio" component={Error} />
            <Route path="/portfolio-v2" component={Error} />
            <Route path="/portfolio-details" component={Error} />
            <Route path="/team" component={Error} />

            <Route path="/team-details" component={TeamDetails} />

            <Route path="/faq" component={Faq} />

            {/* <Route path="/coming-soon" component={ComingSoon} /> */}
            <Route path="/coming-soon" component={Error} />

            <Route path="/404" component={Error} />

            {/* <Route path="/location" component={Location} />
            <Route path="/shop" component={Shop} />
             */}
            <Route path="/location" component={Error} />
            <Route path="/shop" component={Error} />

            <Route path="/shop-grid" component={ShopGrid} />
            {/* <Route path="/shop-left-sidebar" component={ShopLeftSidebar} />
            <Route path="/shop-right-sidebar" component={ShopRightSidebar} /> */}
            <Route path="/shop-left-sidebar" component={Error} />
            <Route path="/shop-right-sidebar" component={Error} />

            <Route path="/product-details" component={ProdductDetails} />
            {/* blog */}
            {/* <Route path="/blog-grid" component={BlogGrid} />
            <Route path="/blog-left-sidebar" component={BlogLeftSidebar} />
            <Route path="/blog-right-sidebar" component={BlogRightSidebar} />
            <Route path="/blog" component={Blog} /> */}
            {/* <Route path="/blog-details" component={BlogDetails} /> */}
            <Route path="/blog-grid" component={Error} />
            <Route path="/blog-left-sidebar" component={Error} />
            <Route path="/blog-right-sidebar" component={Error} />
            <Route path="/blog" component={Error} />
            <Route path="/blog-details" component={Error} />

            <Route path="/contact" component={ContactMain} />

            {/* <Route path="/cart" component={Cart} />
            <Route path="/checkout" component={Checkout} />
            <Route path="/my-account" component={MyAccount} /> */}
            <Route path="/cart" component={Error} />
            <Route path="/checkout" component={Error} />
            <Route path="/my-account" component={Error} />


            <Route path="/vip" component={Login} />
            <Route path="/register" component={Register} />

            
            {/* <Route path="/add-listing" component={AddListing} />
            <Route path="/wishlist" component={Wishlist} />
            <Route path="/order-tracking" component={OrderTracking} />
            <Route path="/history" component={History} />
            <Route path="/admin" component={Admin} /> */}
            <Route path="/add-listing" component={Error} />
            <Route path="/wishlist" component={Error} />
            <Route path="/order-tracking" component={Error} />
            <Route path="/history" component={Error} />
            <Route path="/admin" component={Error} />
          </Switch>
        </div>
      </HashRouter>
    </div>
  );
};

export default Root;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Root />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("quarter")
);
