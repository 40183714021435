import React from "react";
import { useSelector } from "react-redux";

const ProductSliderMain = () => {

  const { condoData } = useSelector((state) => state.data);

  return (
    <div className="ltn__img-slider-area mb-90">
      <div className="container">
        <div className="row ltn__image-slider-5-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
          {condoData.slider.map((item, index) => (
            <div className="col-lg-12" key={index}>
              <div className="ltn__img-slide-item-4">
                <a href={item} target="_blank" rel="noopener noreferrer">
                  <img
                    src={item}
                    alt="Image1"
                    height={"220px"}
                    width={"520px"}
                  />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductSliderMain;
