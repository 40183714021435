import React from "react";
import { Link } from "react-router-dom";
import { Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import "antd/dist/antd.css";


const ProductItem = (product) => {
  const { item, setItemObject } = product;
  const [s3Link, setS3Link] = useState("");


 

  useEffect(() => {
    const getS3Image = async(key) => {
      try {
        const image = await Storage.get(key, { level: "public"});
        console.log(image);
        setS3Link(image);
      } catch (error) {
        console.log("Error loading file: ", error)
      }
    }
    getS3Image(item.image);
  }, [])



  
  return (
    <div>
      {/* <div className="col-lg-4 col-sm-6 col-12"> */}
      <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
        <div className="product-img">
          <Link to="/product-details">
            <img
              // src={`assets/img/condo/${item.image}`}
              src={s3Link}
              alt="#"
              width="100%"
              height="250px"
            />
          </Link>
          {/* <div className="real-estate-agent">
            <div className="agent-img">
              <Link to="/team-details">
                <img src={"assets/img/condo/JPcrop.jpg"} alt="#" />
              </Link>
            </div>
          </div> */}
        </div>
        <div className="product-info">
          <div className="product-badge">
            <ul>
              <li
                className="sale-badg"
                // style={{ cursor: "pointer" }}
                // data-bs-toggle="modal"
                // data-bs-target="#quick_view_modal"
              >
                Register Now
              </li>
            </ul>
          </div>
          <h2 className="product-title go-top">
            <Link to="/product-details">{item.propertyName}</Link>
          </h2>
          <div className="product-img-location">
            <ul>
              <li className="go-top">
                {/* <Link to="/contact"> */}
                  <i className="flaticon-pin" /> {item.address}
                {/* </Link> */}
              </li>
            </ul>
          </div>
          <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
            <li>
              <span>{item.details.bedrooms} </span>
              Bedrooms
            </li>
            {/* <li>
                <span>2 </span>
                Bathrooms
              </li> */}
            <li>
              <span>{item.details.floorspace} sq ft.</span>
            </li>
          </ul>
        
        </div>
        <div className="product-info-bottom">
          <div className="product-price">
            <span>
              {item.startingPrice}
              <label>/Starting Price</label>
            </span>
          </div>
        </div>
      </div>
    
    </div>
  );
};

export default ProductItem;
