/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:be6271d4-70ab-4a74-8316-a42f7fc3bab9",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_IOkqSIldF",
    "aws_user_pools_web_client_id": "6i9e818fjs8n0ifh21a8au5dqi",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "adminpresalecondo-storage-8364a5c9235628-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
