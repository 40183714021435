import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messageData: {
    name: "",
    email: "",
    phoneNumber: "",
    realtor: "",
    property: "",
    message: "",
  },
  isLoading: true,
  error: {
    name: "",
    email: "",
    phoneNumber: "",
    realtor: "Do you have a Realtor?",
    property: "",
    message: ""
  },
  button: "SEND",
  buttonRegister: "SEND",
  buttonVIP: "SEND",
  errors: {}
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setName: (state, action) => {
        state.messageData.name = action.payload;
    },
    setEmail: (state, action) => {
        state.messageData.email = action.payload;
    },
    setPhoneNumber: (state, action) => {
        state.messageData.phoneNumber = action.payload;
    },
    setRealtor: (state, action) => {
        state.messageData.realtor = action.payload;
    },
    setProperty: (state, action) => {
        state.messageData.property = action.payload;
    },
    setMessage: (state, action) => {
        state.messageData.message = action.payload;
    },
    setButton: (state, action) => {
      state.button = action.payload;
    },
    setButtonRegister: (state, action) => {
      state.buttonRegister = action.payload;
    },
    setButtonVIP: (state, action) => {
      state.buttonVIP = action.payload;
    },
    setErrorName: (state, action) => {
      state.error.name = action.payload;
    },
    setErrorEmail: (state, action) => {
      state.error.email = action.payload;
    },
    setErrorPhoneNumber: (state, action) => {
      state.error.phoneNumber = action.payload;
    },
    setErrorRealtor: (state, action) => {
      state.error.realtor = action.payload;
    },
    setErrorProperty: (state, action) => {
      state.error.property = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.error.message = action.payload;
    },
    setReset: (state) => {
      state.messageData.name = "";
      state.messageData.email = "";
      state.messageData.phoneNumber = "";
      state.messageData.realtor = "";
      state.messageData.message = "";
      state.messageData.property = "";
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    removeErrorMessage: (state) => {
      state.errors.message = null;
    }
  }
});

export const {
    setName,
    setEmail,
    setPhoneNumber,
    setRealtor,
    setProperty,
    setMessage,
    setButton,
    setButtonRegister,
    setButtonVIP,
    setErrorName,
    setErrorEmail,
    setErrorPhoneNumber,
    setErrorRealtor,
    setErrorProperty,
    setErrorMessage,
    setReset,
    setErrors,
    removeErrorMessage

} = messageSlice.actions;

export default messageSlice.reducer;
