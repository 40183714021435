import React from 'react';
// import Navbar from './global-components/navbar';
// import PageHeader from './global-components/page-header';
// import Register from './section-components/register';
// import CallToActionV1 from './section-components/call-to-action-v1';
// import Footer from './global-components/footer';
import PreRegister from '../prod/screens/PreRegister';
import NavbarMain from '../prod/components/NavbarMain';
import FooterMain from '../prod/components/FooterMain';

const RegisterV1 = () => {
    return <div>
        {/* <Navbar /> */}
        <NavbarMain />
        <PreRegister />
        {/* <PageHeader headertitle="Account" subheader="Register" /> */}
        {/* <Register /> */}
        {/* <CallToActionV1 /> */}
        {/* <Footer /> */}
        <FooterMain />
    </div>
}

export default RegisterV1

