import { configureStore } from "@reduxjs/toolkit";
import messageReducer from "./feature/messageSlice";
import dataReducer from "./feature/dataSlice";
import getDataReducer from "./feature/getDataSlice";
import updateDataReducer from "./feature/updateSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const persistConfig = {
    key: "root",
    version: 1,
    storage,
}

const store = configureStore({
    reducer: {
        message: messageReducer,
        data: persistReducer(persistConfig, dataReducer),
        updateData: updateDataReducer,
        getData: getDataReducer,
    },
});

export default store;