import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class AboutV4 extends Component {
  render() {
    return (
      <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img src={"assets/img/condo/about2.jpg"} alt="About Us" />
                <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                  {/* <div className="ltn__video-img ltn__animation-pulse1">
                    <img
                      src={"assets/img/others/8.png"}
                      alt="video popup bg image"
                    />
                    <a
                      className="ltn__video-icon-2 ltn__video-icon-2-border---"
                      href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0"
                      data-rel="lightcase:myCollection"
                    >
                      <i className="fa fa-play" />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                    About Us
                  </h6>
                  <h1 className="section-title">
                    The Leading Real Estate Marketplace<span>.</span>
                  </h1>
                  <p>
                    Trust our team of real estate agents to help you find homes
                    for sale. We recognize the level of service that the mobile
                    consumer demands. You have information. You have dreams. We
                    possess experience, integrity, and innovation. Together we
                    create leverage to make your dreams a reality.
                  </p>
                </div>
                <ul className="ltn__list-item-half clearfix">
                  <li>
                    <i className="flaticon-home-2" />
                    Smart Home Design
                  </li>
                  <li>
                    <i className="flaticon-mountain" />
                    Beautiful Scene Around
                  </li>
                  <li>
                    <i className="flaticon-heart" />
                    Exceptional Lifestyle
                  </li>
                  <li>
                    <i className="flaticon-secure" />
                    100% Satisfaction Guarantee
                  </li>
                </ul>
                <div className="ltn__callout bg-overlay-theme-05  mt-30">
                  <p>
                    "We are setting new standards in building and construction,
                    creating innovative and extraordinary living spaces that
                    surpass your wildest dreams."{" "}
                  </p>
                </div>
                <div className="btn-wrapper animated">
                  <Link to="/shop-grid" className="theme-btn-1 btn btn-effect-1">
                    VIEW PROJECTS
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutV4;
