import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Storage } from "aws-amplify";
import { Spin } from "antd";
import "antd/dist/antd.css";

function ProductItem2(product) {
  const { item, setItemObject } = product;
  const [s3Link, setS3Link] = useState("");


 

  useEffect(() => {
    const getS3Image = async(key) => {
      try {
        const image = await Storage.get(key, { level: "public"});
        setS3Link(image);
      } catch (error) {
        console.log("Error loading file: ", error)
      }
    }
    getS3Image(item.image);
  }, [item.image]);

 

  return (
    <div>
      <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
        <div className="product-img">
          <Link to="/product-details">
          <img
              // src={`assets/img/condo/${product.item.image}`}
              src={s3Link}
              alt="#"
              width="400px"
              height="250px"
            />
          </Link>
        </div>
        <div className="product-info">
          <div className="product-badge-price">
            <div className="product-badge">
              <ul>
                <li className="sale-badg">Register Now!</li>
              </ul>
            </div>
            <div className="product-price">
              <span>
                {product.item.startingPrice}<label>/Starting Price</label>
              </span>
            </div>
          </div>
          <h2 className="product-title go-top">
            <Link to="/product-details">{product.item.propertyName}</Link>
          </h2>
          <div className="product-img-location">
            <ul>
              <li className="go-top">
                <Link to="/contact">
                  <i className="flaticon-pin" /> {product.item.address}
                </Link>
              </li>
            </ul>
          </div>
          <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
            <li>
                <span>{product.item.details.buildingType}</span>
            </li>
            <li>
              <span>{product.item.details.bedrooms} </span>
              Bedrooms
            </li>
            <li>
              <span>{product.item.details.floorspace} sq ft.</span>
             
            </li>
          </ul>
        </div>
        <div className="product-info-bottom">
          <div className="real-estate-agent">
            <div className="agent-img">
              <Link to="/team-details">
                <img src={"assets/img/condo/JPcrop.jpg"} alt="#" />
              </Link>
            </div>
            <div className="agent-brief go-top">
              <h6>
                <Link to="/team-details">JP Villanueva</Link>
              </h6>
              <small>Estate Agent</small>
            </div>
          </div>
          <div className="product-hover-action">
            {/* <ul> */}
              {/* <li> */}
                {/* <a
                  href="#"
                  title="Quick View"
                  data-bs-toggle="modal"
                  data-bs-target="#quick_view_modal"
                >
                  <i className="flaticon-expand" />
                </a> */}
                {/* <span className="go-top">
                  <Link to="/register" title="Register">
                    <i className="flaticon-expand" />
                  </Link>
                </span> */}
              {/* </li> */}
              {/* <li>
                <a
                  href="#"
                  title="Wishlist"
                  data-bs-toggle="modal"
                  data-bs-target="#liton_wishlist_modal"
                >
                  <i className="flaticon-heart-1" />
                </a>
              </li> */}
              {/* <li>
                <span className="go-top">
                  <Link to="/product-details" title="Product Details">
                    <i className="flaticon-add" />
                  </Link>
                </span>
              </li> */}
            {/* </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductItem2;
