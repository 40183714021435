import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setButton,
  setEmail,
  setErrorEmail,
  setErrorMessage,
  setErrorName,
  setErrorPhoneNumber,
  setMessage,
  setName,
  setPhoneNumber,
  setRealtor,
  setReset,
  setErrors,
} from "../../redux/feature/messageSlice";

const ContactFormMain = () => {
  const dispatch = useDispatch();
  const { messageData, button, errors } = useSelector(
    (state) => state.message
  );

  useEffect(() => {
    return () => dispatch(setButton("SEND"));
  }, [])

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePhone = (phone) => {
    const re = /^(1\s?)?(\([0-9]{3}\)|[0-9]{3})[-. ]?[0-9]{3}[-. ]?[0-9]{4}$/;
    return re.test(phone);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!messageData.name) newErrors.name = "* Full name required";
    if (!messageData.email) newErrors.email = "*Email required";
    if (!validateEmail(messageData.email))
      newErrors.email = "*Invalid email format";
    if (!messageData.phoneNumber) newErrors.phoneNumber = "*required";
    if (!validatePhone(messageData.phoneNumber))
      newErrors.phoneNumber = "*Accepted format (xxx) xxx-xxxx";
    if (!messageData.message) newErrors.message = "*Message required";
    if (!messageData.realtor) newErrors.realtor = "*Yes or No prompt required";

    dispatch(setErrors(newErrors));
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      console.log("Validated!");

      const date = new Date();

      fetch(
        "https://9gf4bi3vx1.execute-api.us-west-2.amazonaws.com/sendEmail",
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            senderName: "presalegreatervan@gmail.com",
            // senderName: "ethanlloyd21@gmail.com",
            senderEmail: "presalegreatervan@gmail.com",
            message: `<p><b>Name: </b> ${messageData.name}</p>
          <p><b>Email: </b>${messageData.email}</p>
          <p><b>Phone Number: </b>${messageData.phoneNumber} </p>
          <p><b>Property: </b>${messageData.property}</p>
          <p><b>Realtor: </b>${messageData.realtor}</p>
          <p><b>Message: </b> </p>
          <p>${messageData.message}</p>
          <p><b>Date: ${date.toDateString()} </p>`,
            date: date.toString(),
            subject: "Message from:",
            name: messageData.name,
            email: messageData.email,
          }),
        }
      );

      dispatch(setReset());
      dispatch(setButton("Submited Successful!"));
    }
  };

  



  return (
    <div className="ltn__contact-message-area mb-120 mb--100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__form-box contact-form-box box-shadow white-bg">
              <h4 className="title-2">Contact Us</h4>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="name"
                        value={messageData.name}
                        onChange={(e) => {
                          dispatch(setName(e.target.value));
                          dispatch(setErrorName(""));
                        }}
                        placeholder="Enter your name*"
                      />
                      {errors.name && (
                        <p>
                          <span style={{ color: "#bc3928" }}>{errors.name}</span>
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-email ltn__custom-icon">
                      <input
                        type="email"
                        name="email"
                        value={messageData.email}
                        onChange={(e) => {
                          dispatch(setEmail(e.target.value));
                          dispatch(setErrorEmail(""));
                        }}
                        placeholder="Enter email address*"
                      />
                      {errors.email && (
                        <p>
                          <span style={{ color: "#bc3928" }}>
                            {errors.email}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-textarea ltn__custom-icon">
                      <input
                        type="text"
                        name="realtor"
                        value={messageData.realtor}
                        onChange={(e) => dispatch(setRealtor(e.target.value))}
                        placeholder="Do you need a Realtor? Yes/No"
                      />
                       {errors.realtor && (
                        <p>
                          <span style={{ color: "#bc3928" }}>
                            {errors.realtor}
                          </span>
                        </p>
                      )}
                    </div>
                    {/* <div className="input-item"> */}
                    {/* <select name="realtor" value={messageData.realtor} onChange={(e) => dispatch(setRealtor(e.target.event))}>
                        
                        <option value="">Do you have a Realtor?</option>
                        <option value="Yes">Yes </option>
                        <option value="No">No </option>
                      </select> */}
                    {/* <label>
                        <input
                          type="radio"
                          value="yes"
                          checked={drop === "yes"}
                          onChange={handleChange}
                        />
                        Yes, I do have a Realtor Agent
                      </label>
                      { " "}
                      <label>
                        <input
                          type="radio"
                          value="no"
                          checked={drop === "no"}
                          onChange={handleChange}
                        />
                        No, I need a Realtor Agent
                      </label> */}
                    {/* </div> */}
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-phone ltn__custom-icon">
                      <input
                        type="text"
                        name="phone"
                        value={messageData.phoneNumber}
                        onChange={(e) => {
                          dispatch(setPhoneNumber(e.target.value));
                          dispatch(setErrorPhoneNumber(""));
                        }}
                        placeholder="Enter phone number*"
                      />
                      {errors.phoneNumber && (
                        <p>
                          <span style={{ color: "#bc3928" }}>
                            {errors.phoneNumber}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="input-item input-item-textarea ltn__custom-icon">
                  <textarea
                    name="message"
                    placeholder="Enter message*"
                    value={messageData.message}
                    onChange={(e) => {
                      dispatch(setMessage(e.target.value));
                      dispatch(setErrorMessage(""));
                    }}
                  />
                </div>
                {errors.message && (
                  <p>
                    <span style={{ color: "#bc3928" }}>{errors.message}</span>
                  </p>
                )}
                <p>
                  <label className="input-info-save mb-0">
                    <input type="checkbox" name="agree" /> Save my name, email,
                    and website in this browser for the next time I comment.
                  </label>
                </p>
                <div className="btn-wrapper mt-0">
                  <button
                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                    type="submit"
                  >
                    {button}
                  </button>
                </div>
                <p className="form-messege mb-0 mt-20" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactFormMain;
