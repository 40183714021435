import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  condoData: {
    id: "",
    propertyName: "",
    address: "",
    image: "",
    city: "",
    developer: "",
    startingPrice: "",
    details: {
      buildingType: "",
      assignmentFee: "",
      strataFee: "",
      depositStructure: [],
      stories: "",
      units: "",
      floorspace: "",
      bedrooms: "",
      estimatedCompletion: "",
    },
    amenities: [],
    scores: {
      walkScore: {
        score: 0,
        description: "",
      },
      bikeScore: {
        score: 0,
        description: "",
      },
    },
  },
};

const dataSlice = createSlice({
    name: "data",
    initialState,
    reducers: {
        setData: (state, action) => {
            state.condoData = action.payload;
        }
    }
});

export const {
    setData
} = dataSlice.actions;

export default dataSlice.reducer;