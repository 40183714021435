import React, { Component } from "react";
import { Link } from "react-router-dom";

class NavbarMain extends Component {
  render() {
    let CustomClass = this.props.CustomClass ? this.props.CustomClass : "";
    return (
      <div>
        <header
          className={
            "ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent--- gradient-color-4--- " +
            CustomClass
          }
        >
          {/* ltn__header-top-area start */}

          {/* ltn__header-top-area end */}
          {/* ltn__header-middle-area start */}
          <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="site-logo-wrap">
                    <div className="site-logo go-top">
                      <Link to="/">
                        <img src={"assets/img/presale.png"} alt="Logo" />
                      </Link>
                    </div>
                    <div className="get-support clearfix d-none">
                      <div className="get-support-icon">
                        <i className="icon-call" />
                      </div>
                      <div className="get-support-info">
                        <h6>Get Support</h6>
                        <h4>
                          <a href="tel:+123456789">555</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col header-menu-column">
                  <div className="header-menu d-none d-xl-block go-top">
                    <nav>
                      <div className="ltn__main-menu">
                        <ul>
                          <li className="menu-icon">
                            <Link to="/">Home</Link>
                          </li>
                          <li className="menu-icon">
                            <Link to="/about">About</Link>
                          </li>
                          <li className="menu-icon">
                            <Link to="/shop-grid">All Presales</Link>
                          </li>

                          <li className="menu-icon">
                            <Link to="/faq">FAQ</Link>
                          </li>
                          <li className="menu-icon">
                            <a
                              href="https://main.d3c6l8est0ho1u.amplifyapp.com/"
                              title="Admin"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Admin
                            </a>
                          </li>
                          <li className="menu-icon">
                            <Link to="/contact">Contact</Link>
                          </li>
                          <li className="special-link">
                            <Link to="/register">Register Now</Link>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="col--- ltn__header-options ltn__header-options-2 ">
                  {/* Mobile Menu Button */}
                  <div className="mobile-menu-toggle d-xl-none">
                    <a
                      href="#ltn__utilize-mobile-menu"
                      className="ltn__utilize-toggle"
                    >
                      <svg viewBox="0 0 800 600">
                        <path
                          d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                          id="top"
                        />
                        <path d="M300,320 L540,320" id="middle" />
                        <path
                          d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                          id="bottom"
                          transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ltn__header-middle-area end */}
        </header>
        <div
          id="ltn__utilize-mobile-menu"
          className="ltn__utilize ltn__utilize-mobile-menu"
        >
          <div className="ltn__utilize-menu-inner ltn__scrollbar">
            <div className="ltn__utilize-menu-head">
              <div className="site-logo">
                <Link to="/">
                  <img src={"assets/img/presale.png"} alt="Logo" />
                </Link>
              </div>
              <button className="ltn__utilize-close">×</button>
            </div>
            {/* <div className="ltn__utilize-menu-search-form">
					<form action={"#"}>
						<input type="text" placeholder="Search..." />
						<button><i className="fas fa-search" /></button>
					</form>
					</div> */}
            <div className="ltn__utilize-menu">
              <ul>
                <li className="menu-icon">
                  <Link to="/">Home</Link>
                </li>
                <li className="menu-icon">
                  <Link to="/about">About</Link>
                </li>
                <li className="menu-icon">
                  <Link to="/shop-grid">All Presales</Link>
                </li>
                <li className="menu-icon">
                  <Link to="/faq">FAQ</Link>
                </li>
                <li className="menu-icon">
                  <a
                    href="https://main.d3c6l8est0ho1u.amplifyapp.com/"
                    title="Admin"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Admin
                  </a>
                </li>
                <li className="menu-icon">
                  <Link to="/contact">Contact</Link>
                </li>
                <li className="menu-icon">
                  <Link to="/register">Register</Link>
                </li>
                <li className="menu-icon">
                  <Link to="/vip">Join VIP</Link>
                </li>
              </ul>
            </div>
            <div className="ltn__utilize-buttons ltn__utilize-buttons-2">
              <div className="ltn__social-media-2">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/presalegreatervan?mibextid=ZbWKwL"
                      title="Facebook"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.twitter.com" title="Twitter">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com" title="Linkedin">
                      <i className="fab fa-linkedin" />
                    </a>
                  </li>
                  {/* <li>
                  <a href="#" title="Instagram">
                    <i className="fab fa-instagram" />
                  </a>
                </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NavbarMain;
