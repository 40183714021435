import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class FaqV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__faq-area mb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="ltn__faq-inner ltn__faq-inner-2">
                <div id="accordion_2">
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-1"
                      aria-expanded="false"
                    >
                      What is a PRESALE?
                    </h6>
                    <div
                      id="faq-item-2-1"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          A presale is when a developer offers condos to the
                          market in some cases before construction of the
                          building has started. When you purchase a presale, you
                          are paying for a future home.{" "}
                        </p>
                        <ul>
                          <li>You only need to pay a deposit.</li>
                          <li>
                            You will still pay the full price, but you don’t
                            need to take out a home loan on the property right
                            away.
                          </li>
                          <li>
                            The deposit will range from 5 to 10% of the purchase
                            price, and further 10 to 15% increments throughout
                            the building project.
                          </li>
                          <li>
                            Your deposit is held in a trust account until the
                            building is completed.
                          </li>
                          <li>
                            If for any reason the developer does not finish the
                            building, the full amount will be returned to you.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-2"
                      aria-expanded="true"
                    >
                      What does PreSale Condo BC do?
                    </h6>
                    <div
                      id="faq-item-2-2"
                      className="collapse show"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <div className="ltn__video-img alignleft">
                          <img
                            src={"assets/img/condo/oaks.png"}
                            alt="video popup bg"
                          />
                          {/* <a
                            className="ltn__video-icon-2 ltn__video-icon-2-small ltn__video-icon-2-border----"
                            href="https://www.facebook.com/reel/5002862176500029"
                            data-rel="lightcase:myCollection"
                          >
                            <i className="fa fa-play" />
                          </a> */}
                        </div>
                        <p>
                          At our reputable agency, we pride ourselves on
                          delivering top-notch assistance to clients seeking new
                          homes in British Columbia, Canada. With an unwavering
                          focus on customer satisfaction, we navigate the
                          complexities of presale condos, off-plan properties,
                          new homes, and new townhomes with utmost
                          professionalism and expertise, ensuring personalized
                          guidance throughout the entire buying and selling
                          journey. Trust our team of licensed Realtors to
                          provide you with comprehensive market insights,
                          reliable advice, and strategic negotiation skills,
                          enabling you to make informed decisions and maximize
                          your real estate investments. Contact us today to
                          pre-register for exclusive access to the latest
                          presale opportunities and secure your dream home
                          before they hit the market. Experience the difference
                          of working with a dedicated team that prioritizes your
                          needs and strives for excellence in every transaction.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-3"
                      aria-expanded="false"
                    >
                      We do not do the following:
                    </h6>
                    <div
                      id="faq-item-2-3"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <ul>
                          <li>
                            Provide information, pricing, floor plans, and/or
                            advice to people working with other Realtors.
                          </li>
                          <li>
                            Provide information, pricing, floor plans, and/or
                            advice to other Realtors.
                          </li>
                          <li>Provide strata management services.</li>
                          <li>
                            Provide rental management services (We DO work with
                            very good rental management companies and are happy
                            to make an introduction).
                          </li>
                          <li>Rental placement or rental search services.</li>
                          <li>Develop and/or build properties.</li>
                          <li>Project sales/project marketing.</li>
                          <li>
                            Share information with people who do not provide a
                            phone number.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-4"
                      aria-expanded="false"
                    >
                      What areas do we cover?
                    </h6>
                    <div
                      id="faq-item-2-4"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          We cover most of the lower mainland including Metro
                          Vancouver and the Fraser Valley
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  {/* <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-5"
                      aria-expanded="false"
                    >
                      Are my details secured?
                    </h6>
                    <div
                      id="faq-item-2-5"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Scelerisque eleifend donec
                          pretium vulputate sapien nec sagittis. Proin libero
                          nunc consequat interdum. Condimentum lacinia quis vel
                          eros donec ac. Mauris sit amet massa vitae tortor.
                          Quisque id diam vel quam elementum pulvinar. Gravida
                          in fermentum et sollicitudin ac orci phasellus.
                          Facilisis gravida neque convallis a cras semper. Non
                          arcu risus quis varius quam quisque id.
                        </p>
                      </div>
                    </div>
                  </div> */}
                  {/* card */}
                  {/* <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-6"
                      aria-expanded="false"
                    >
                      Sale code is not working
                    </h6>
                    <div
                      id="faq-item-2-6"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Scelerisque eleifend donec
                          pretium vulputate sapien nec sagittis. Proin libero
                          nunc consequat interdum. Condimentum lacinia quis vel
                          eros donec ac. Mauris sit amet massa vitae tortor.
                          Quisque id diam vel quam elementum pulvinar. Gravida
                          in fermentum et sollicitudin ac orci phasellus.
                          Facilisis gravida neque convallis a cras semper. Non
                          arcu risus quis varius quam quisque id.
                        </p>
                      </div>
                    </div>
                  </div> */}
                  {/* card */}
                  {/* <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-7"
                      aria-expanded="false"
                    >
                      How do I make payment by my credit card
                    </h6>
                    <div
                      id="faq-item-2-7"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Scelerisque eleifend donec
                          pretium vulputate sapien nec sagittis. Proin libero
                          nunc consequat interdum. Condimentum lacinia quis vel
                          eros donec ac. Mauris sit amet massa vitae tortor.
                          Quisque id diam vel quam elementum pulvinar. Gravida
                          in fermentum et sollicitudin ac orci phasellus.
                          Facilisis gravida neque convallis a cras semper. Non
                          arcu risus quis varius quam quisque id.
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="need-support text-center mt-100">
                  {/* <h2>Still need help? Reach out to support 24/7:</h2>
                  <div className="btn-wrapper mb-30 go-top">
                    <Link to="/contact" className="theme-btn-1 btn">
                      Contact Us
                    </Link>
                  </div>
                  <h3>
                    <i className="fas fa-phone" /> +0123-456-789
                  </h3> */}
                   <h2>Still need help?</h2>
                  <div className="btn-wrapper mb-30 go-top">
                    <Link to="/contact" className="theme-btn-1 btn">
                      <span style={{ color: "white" }}>Contact Us</span>
                    </Link>
                  </div>
                  <h5>
                    <i className="fas fa-phone" /> +1 778 865 9443
                  </h5>
                  <h5>
                    <i className="fas fa-envelope" />{" "}
                    Presalegreatervan@gmail.com
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar-area ltn__right-sidebar">
                {/* Newsletter Widget */}
                <div>
                  {/* <h6 className="ltn__widget-sub-title">// subscribe</h6>
                  <h4 className="ltn__widget-title">Get Newsletter</h4>
                  <form action="#">
                    <input type="text" name="search" placeholder="Search" />
                    <button type="submit">
                      <i className="fas fa-search" />
                    </button>
                  </form>
                  <div className="ltn__newsletter-bg-icon">
                    <i className="fas fa-envelope-open-text" />
                  </div> */}
                  <h2>Pre-Sale Condo BC</h2>
                  <p>
                    A pre-sale condo refers to a condominium unit that is
                    available for purchase before its construction is completed.
                    Buyers can secure a unit at a pre-determined price and often
                    have the opportunity to choose from various floor plans and
                    finishes.
                  </p>
                  <p>
                    Buying a pre-sale condo allows you to secure a property at a
                    potentially lower price compared to the market value upon
                    completion. It also gives you the advantage of selecting
                    your preferred unit and enjoying the benefits of brand-new
                    construction and modern amenities.
                  </p>
                  <p>
                    By registering with Pre-Sale Condo Vancouver Area, you gain
                    exclusive access to our curated selection of pre-sale
                    opportunities before they are released to the public. This
                    means you have the first choice of available units and the
                    chance to secure prime locations. you will receive regular
                    updates on new pre-sale projects, VIP previews, and early
                    bird promotions. We strive to keep you informed about the
                    latest opportunities in the Vancouver area.
                  </p>
                  <p>
                    Once you purchase a pre-sale condo, our team will guide you
                    through the remaining steps, such as signing the contract,
                    providing progress updates during construction, and
                    assisting with the final closing process.
                  </p>
                  <p>
                    Pre-sale condos can be a lucrative investment opportunity.
                    Our team can provide information on potential rental yields,
                    market trends, and the best neighborhoods for investment in
                    the Vancouver area.
                  </p>
                  <p>
                    To begin your pre-sale condo journey, simply register on our
                    website to gain access to our exclusive offerings. Our team
                    is here to answer any additional questions you may have and
                    provide personalized assistance throughout the process.
                  </p>
                 
                </div>
                {/* Banner Widget */}
                {/* <div className="widget ltn__banner-widget go-top">
                  <Link to="shop.html">
                    <img
                      src={publicUrl + "assets/img/banner/banner-3.jpg"}
                      alt="Banner Image"
                    />
                  </Link>
                </div> */}
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FaqV1;
