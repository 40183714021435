import React from "react";
import NavbarMain from "../components/NavbarMain";
import PageHeaderMain from "../components/PageHeaderMain";
import ContactFormMain from "../components/ContactFormMain";
import ContactInfoMain from "../components/ContactInfoMain";
import MapMain from "../components/MapMain";
import CallToActonMain from "../components/CallToActionMain";
import FooterMain from "../components/FooterMain";

const ContactMain = () => {
  return (
    <div>
      <NavbarMain />
      <PageHeaderMain headertitle="Contact Us" subheader="Contact" />
      <ContactInfoMain />
      <ContactFormMain />
      <MapMain />
      <CallToActonMain />
      <FooterMain />
    </div>
  );
};

export default ContactMain;
