import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { setGetData, setSurrey, setVancouver } from "../../redux/feature/getDataSlice";
import { allCitiesData } from "../../prod/assets/data/data";
import { useDispatch, useSelector } from "react-redux";

const ProductSliderV2 = () => {
  const appDispatch = useDispatch();
  const { surrey } = useSelector((state) => state.getData);

 

  useEffect(() => {
    axios
      .get("https://hyonlxjq70.execute-api.us-west-2.amazonaws.com/dev/items/")
      .then((response) => {
        console.log(response.data.Items);
        appDispatch(setGetData(response.data));

        const surrey = response.data.Items.filter((item) => item.city === "Surrey").length
        appDispatch(setSurrey(surrey));

        const vancouver = response.data.Items.filter((item) => item.city === "Vancouver").length
        appDispatch(setVancouver(vancouver));


        // appDispatch(setDatabaseData(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div
      className="ltn__search-by-place-area before-bg-top bg-image-top--- pt-115 pb-70"
      data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center---">
              <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                Area Properties
              </h6>
              <h1 className="section-title">
                Find Your Dream Home <br />
                Search By Area
              </h1>
            </div>
          </div>
        </div>
        <div className="row ltn__search-by-place-slider-1-active slick-arrow-1 go-top">
          <div className="col-lg-4">
            <div className="ltn__search-by-place-item">
              <div className="search-by-place-img">
                <Link to="/shop-grid">
                  <img
                    src={"assets/img/condo/piano.png"}
                    alt="#"
                    width={"100%"}
                    height={"260px"}
                  />
                </Link>
                <div className="search-by-place-badge">
                  <ul>
                    <li>{ surrey > 0 ? <span>{surrey} PROPERTIES</span> : "COMMING SOON"}</li>
                    {/* <li>{surrey} Properties</li> */}
                  </ul>
                </div>
              </div>
              <div className="search-by-place-info">
                <h6>
                  <Link to="/shop-grid">British Columbia</Link>
                </h6>
                <h4>
                  <Link to="/shop-grid">Surrey</Link>
                </h4>
                <div className="search-by-place-btn">
                  <Link to="/shop-grid">
                    View Property <i className="flaticon-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__search-by-place-item">
              <div className="search-by-place-img">
                <Link to="/product-details">
                  <img
                    src={"assets/img/condo/luxe-richmond-featured.png"}
                    alt="#"
                    width={"100%"}
                    height={"260px"}
                  />
                </Link>
                <div className="search-by-place-badge">
                  <ul>
                    <li>Coming Soon</li>
                  </ul>
                </div>
              </div>
              <div className="search-by-place-info">
                <h6>
                  <Link to="/contact">Brithis Columbia</Link>
                </h6>
                <h4>
                  <Link to="/product-details">Richmond</Link>
                </h4>
                <div className="search-by-place-btn">
                  <Link to="/">
                    Available Soon <i className="flaticon-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__search-by-place-item">
              <div className="search-by-place-img">
                <Link to="/product-details">
                  <img
                    src={"assets/img/condo/clemande_vancouver.jpg"}
                    alt="#"
                    width={"100%"}
                    height={"260px"}
                  />
                </Link>
                <div className="search-by-place-badge">
                  <ul>
                    <li>Coming Soon</li>
                  </ul>
                </div>
              </div>
              <div className="search-by-place-info">
                <h6>
                  <Link to="/contact">British Columbia</Link>
                </h6>
                <h4>
                  <Link to="/product-details">Vancouver</Link>
                </h4>
                <div className="search-by-place-btn">
                  <Link to="/">
                    Available Soon <i className="flaticon-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__search-by-place-item">
              <div className="search-by-place-img">
                <Link to="/product-details">
                  <img
                    src={"assets/img/condo/oaks.png"}
                    alt="#"
                    width={"100%"}
                    height={"260px"}
                  />
                </Link>
                <div className="search-by-place-badge">
                  <ul>
                    <li>Coming Soon</li>
                  </ul>
                </div>
              </div>
              <div className="search-by-place-info">
                <h6>
                  <Link to="/contact">British Columbia</Link>
                </h6>
                <h4>
                  <Link to="/product-details">Coquitlam</Link>
                </h4>
                <div className="search-by-place-btn">
                  <Link to="/">
                    Available Soon <i className="flaticon-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__search-by-place-item">
              <div className="search-by-place-img">
                <Link to="/product-details">
                  <img
                    src={"assets/img/condo/chroma.png"}
                    alt="#"
                    width={"100%"}
                    height={"260px"}
                  />
                </Link>
                <div className="search-by-place-badge">
                  <ul>
                    <li>Coming Soon</li>
                  </ul>
                </div>
              </div>
              <div className="search-by-place-info">
                <h6>
                  <Link to="/contact">British Columbia</Link>
                </h6>
                <h4>
                  <Link to="/product-details">Port Moody</Link>
                </h4>
                <div className="search-by-place-btn">
                  <Link to="/">
                    Available Soon <i className="flaticon-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__search-by-place-item">
              <div className="search-by-place-img">
                <Link to="/product-details">
                  <img
                    src={"assets/img/condo/camden_langley.png"}
                    alt="#"
                    width={"100%"}
                    height={"260px"}
                  />
                </Link>
                <div className="search-by-place-badge">
                  <ul>
                    <li>Coming Soon</li>
                  </ul>
                </div>
              </div>
              <div className="search-by-place-info">
                <h6>
                  <Link to="/contact">British Columbia</Link>
                </h6>
                <h4>
                  <Link to="/product-details">Langley</Link>
                </h4>
                <div className="search-by-place-btn">
                  <Link to="/">
                    Available Soon <i className="flaticon-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__search-by-place-item">
              <div className="search-by-place-img">
                <Link to="/product-details">
                  <img
                    src={"assets/img/condo/jem_abbotsford.png"}
                    alt="#"
                    width={"100%"}
                    height={"260px"}
                  />
                </Link>
                <div className="search-by-place-badge">
                  <ul>
                    <li>Coming Soon</li>
                  </ul>
                </div>
              </div>
              <div className="search-by-place-info">
                <h6>
                  <Link to="/contact">British Columbia</Link>
                </h6>
                <h4>
                  <Link to="/product-details">Abbotsford</Link>
                </h4>
                <div className="search-by-place-btn">
                  <Link to="/">
                    Available Soon <i className="flaticon-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__search-by-place-item">
              <div className="search-by-place-img">
                <Link to="/product-details">
                  <img
                    src={"assets/img/condo/riviere-maple.jpg"}
                    alt="#"
                    width={"100%"}
                    height={"260px"}
                  />
                </Link>
                <div className="search-by-place-badge">
                  <ul>
                    <li>Coming Soon</li>
                  </ul>
                </div>
              </div>
              <div className="search-by-place-info">
                <h6>
                  <Link to="/contact">British Columbia</Link>
                </h6>
                <h4>
                  <Link to="/product-details">Maple Ridge</Link>
                </h4>
                <div className="search-by-place-btn">
                  <Link to="/">
                    Available Soon <i className="flaticon-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__search-by-place-item">
              <div className="search-by-place-img">
                <Link to="/product-details">
                  <img
                    src={"assets/img/condo/burnaby.png"}
                    alt="#"
                    width={"100%"}
                    height={"260px"}
                  />
                </Link>
                <div className="search-by-place-badge">
                  <ul>
                    <li>Coming Soon</li>
                  </ul>
                </div>
              </div>
              <div className="search-by-place-info">
                <h6>
                  <Link to="/contact">British Columbia</Link>
                </h6>
                <h4>
                  <Link to="/product-details">Burnaby</Link>
                </h4>
                <div className="search-by-place-btn">
                  <Link to="/">
                    Available Soon <i className="flaticon-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </div>
    </div>
  );
};

export default ProductSliderV2;
