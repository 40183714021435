import React from "react";

const MapMain = () => {
  return (
    <div className="google-map mb-120" style={{marginTop: 140}}>
      <iframe
        // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d83327.56084687423!2d-123.20647546361334!3d49.25770602860634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548673f143a94fb3%3A0xbb9196ea9b81f38b!2sVancouver%2C%20BC%2C%20Canada!5e0!3m2!1sen!2sus!4v1688107752768!5m2!1sen!2sus"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10410.521991755608!2d-123.117535!3d49.2833984!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486717f457f6223%3A0x2153ae94c60217bc!2seXp%20Realty%20-%20Greater%20Vancouver!5e0!3m2!1sen!2sus!4v1688669621331!5m2!1sen!2sus"
        width="100%"
        height="100%"
        allowFullScreen
        aria-hidden="false"
        tabIndex={0}
        title="map"
      ></iframe>
    </div>
  );
};

export default MapMain;
