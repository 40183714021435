import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Login from './section-components/login';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import NavbarMain from '../prod/components/NavbarMain';
import FooterMain from '../prod/components/FooterMain';
import VIPList from '../prod/screens/VIPList';

const LoginV1 = () => {
    return <div>
        <NavbarMain />
        {/* <PageHeader headertitle="Account" subheader="Login" /> */}
        <VIPList />
        {/* <CallToActionV1 /> */}
        <FooterMain />
    </div>
}

export default LoginV1

