import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class TeamDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__team-details-area mb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="ltn__team-details-member-info text-center mb-40">
                <div className="team-details-img">
                  <img
                    src={"assets/img/condo/JPcrop.jpg"}
                    alt="JP"
                  />
                </div>
                <h2>JP Villanueva</h2>
                <h6 className="text-uppercase ltn__secondary-color">
                  Property Seller
                </h6>
                <div className="ltn__social-media-3">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/presalegreatervan?mibextid=ZbWKwL"
                        title="Facebook"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com" title="Twitter">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com" title="Linkedin">
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="ltn__team-details-member-info-details">
                <p>
                  Introducing <b><h2>JP Villanueva</h2></b> an accomplished realtor
                  specializing in pre-sale condos at Presale Condo BC. With a
                  significant track record of success, JP's deep-seated passion
                  for real estate and dedicated service has solidified his
                  position as a trusted advisor to his clients in the Greater
                  Vancouver area.
                </p>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="ltn__team-details-member-about">
                      <ul>
                        <li>
                          <strong>Positions:</strong> Realtor
                        </li>
                        <li>
                          <strong>Location:</strong> Vacouver, BC Canada
                        </li>
                        <li>
                          <strong>Company:</strong> EXP Realty
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="ltn__team-details-member-about">
                      <ul>
                        <li>
                          <strong>Practice Area:</strong> Greater Vancouver
                        </li>
                        <li>
                          <strong>Email:</strong> Presalegreatervan@gmail.com
                        </li>
                        <li>
                          <strong>Phone:</strong> +1 778-865-9443
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <hr />
                <p>
                  As a resident of Vancouver, British Columbia, JP has an
                  intimate, unrivaled knowledge of the Greater Vancouver area.
                  He understands the diverse communities, local markets, and
                  unique nuances that make each neighborhood special. This
                  insight allows JP to confidently guide his clients towards the
                  best investments, considering both their personal preferences
                  and the potential for growth and returns in the property
                  market.
                </p>
                {/* <div className="row ltn__custom-gutter mt-50 mb-20">
                  <div className="col-xl-4 col-sm-6 col-12 go-top">
                    <div className="ltn__feature-item ltn__feature-item-6 text-center">
                      <div className="ltn__feature-icon">
                        <span>
                          <i className="icon-tire" />
                        </span>
                      </div>
                      <div className="ltn__feature-info">
                        <h4>
                          <Link to="/service">Home Buying</Link>
                        </h4>
                        <p>
                          Lorem ipsum dolor sit ame it, consectetur adipisicing
                          elit, sed do eiusmod te mp or
                        </p>
                      
                        <Link
                          className="ltn__service-btn"
                          to="/service-details"
                        >
                          Read More <i className="flaticon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-sm-6 col-12">
                    <div className="ltn__feature-item ltn__feature-item-6 text-center active">
                      <div className="ltn__feature-icon">
                        <span>
                          <i className="icon-mechanic" />
                        </span>
                      </div>
                      <div className="ltn__feature-info go-top">
                        <h4>
                          <Link to="/team">Home Selling</Link>
                        </h4>
                        <p>
                          Lorem ipsum dolor sit ame it, consectetur adipisicing
                          elit, sed do eiusmod te mp or
                        </p>
                      
                        <Link
                          className="ltn__service-btn"
                          to="/service-details"
                        >
                          Read More <i className="flaticon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-sm-6 col-12">
                    <div className="ltn__feature-item ltn__feature-item-6 text-center">
                      <div className="ltn__feature-icon">
                        <span>
                          <i className="icon-wheel-1" />
                        </span>
                      </div>
                      <div className="ltn__feature-info go-top">
                        <h4>
                          <Link to="/service-details">Escrow Services</Link>
                        </h4>
                        <p>
                          Lorem ipsum dolor sit ame it, consectetur adipisicing
                          elit, sed do eiusmod te mp or
                        </p>
               
                        <Link
                          className="ltn__service-btn"
                          to="/service-details"
                        >
                          Read More <i className="flaticon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div> */}
                <p>
                  JP started his real estate career with a simple mission: to
                  make the journey of buying a pre-sale condo as seamless and
                  rewarding as possible for his clients. To do this, he combines
                  his comprehensive understanding of the property market with a
                  personalized approach, taking the time to understand each
                  client's unique needs, preferences, and goals. Whether you're
                  a first-time buyer or a seasoned investor, JP is committed to
                  helping you find a condo that matches your vision and offers
                  excellent value for money.
                </p>
                <p>
                  His approach extends beyond simply finding the perfect condo.
                  JP is skilled in negotiating favorable contract terms and
                  conditions for his clients, ensuring they secure their desired
                  property under the best possible terms. He prides himself on
                  his ability to deliver results, as evidenced by the many
                  successful transactions he has facilitated over his career.
                </p>
                <p>
                  Furthermore, JP's dedication to his clients doesn't stop at
                  the sale. He prioritizes long-lasting relationships, remaining
                  a valuable resource for clients even after closing. He
                  provides continuous advice and support on property management,
                  market trends, and strategies for property portfolio growth.
                  His clients appreciate this level of dedication, as reflected
                  in the numerous positive testimonials he receives. As a
                  realtor at Presale Condo BC, JP is backed by a team that
                  shares his commitment to excellence. With their combined
                  experience and knowledge, JP and the team can provide clients
                  with an extensive selection of pre-sale condos, staying
                  up-to-date with the latest developments in the Greater
                  Vancouver area.
                </p>
                <p>
                  JP has a keen eye for details and aesthetics, an invaluable
                  skill in the world of pre-sale condos. He understands the
                  importance of innovative designs, quality construction, and
                  sustainable practices, which all contribute to a condo's
                  long-term value. His critical evaluation of each property
                  ensures that clients get not only a beautiful home but also a
                  smart, future-proof investment. In a bustling market like
                  Greater Vancouver, staying ahead of trends is critical. JP
                  devotes considerable time and resources to professional
                  development and market research, ensuring he can offer his
                  clients the most accurate, up-to-date advice.
                </p>
                <p>
                  To summarize, JP Villanueva is not just a realtor; he is a
                  guide, a trusted advisor, and a dedicated partner on your
                  journey to find the perfect pre-sale condo. With JP, clients
                  don't just buy a condo; they invest in their future, secure in
                  the knowledge that they're making a well-informed decision
                  that aligns with their goals.
                </p>
                {/* <div className="ltn__form-box contact-form-box box-shadow white-bg mt-50">
                  <h4 className="title-2">Contact for any Inquiry</h4>
                  <form id="contact-form" action="mail.php" method="post">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-item input-item-name ltn__custom-icon">
                          <input
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-item input-item-email ltn__custom-icon">
                          <input
                            type="email"
                            name="email"
                            placeholder="Enter email address"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-item">
                          <select className="nice-select">
                            <option>Select Service Type</option>
                            <option>Property Management </option>
                            <option>Mortgage Service </option>
                            <option>Consulting Service</option>
                            <option>Home Buying</option>
                            <option>Home Selling</option>
                            <option>Escrow Services</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-item input-item-phone ltn__custom-icon">
                          <input
                            type="text"
                            name="phone"
                            placeholder="Enter phone number"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="input-item input-item-textarea ltn__custom-icon">
                      <textarea
                        name="message"
                        placeholder="Enter message"
                        defaultValue={""}
                      />
                    </div>
                    <p>
                      <label className="input-info-save mb-0">
                        <input type="checkbox" name="agree" /> Save my name,
                        email, and website in this browser for the next time I
                        comment.
                      </label>
                    </p>
                    <div className="btn-wrapper mt-0">
                      <button
                        className="btn theme-btn-1 btn-effect-1 text-uppercase"
                        type="submit"
                      >
                        get an free service
                      </button>
                    </div>
                    <p className="form-messege mb-0 mt-20" />
                  </form>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamDetails;
