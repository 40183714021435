import React from "react";
import { Link } from "react-router-dom";

const CallToActonMain = () => {
  return (
    <div className="ltn__call-to-action-area call-to-action-6 before-bg-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="call-to-action-inner call-to-action-inner-6 ltn__secondary-bg position-relative text-center---">
              <div className="coll-to-info text-color-white">
                <h1>Join our VIP list!</h1>
                <p>
                  Gain exclusive VIP privileges to fresh promotions and enticing
                  incentives offered during presales.
                </p>
              </div>
              <div className="btn-wrapper go-top">
                <Link className="btn btn-effect-3 btn-white" to="/contact">
                  Join now! <i className="icon-next" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToActonMain;
