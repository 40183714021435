import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setEmail,
  setMessage,
  setName,
  setPhoneNumber,
  setProperty,
  setRealtor,
  setReset,
  setErrors,
  setButtonRegister,
} from "../../redux/feature/messageSlice";
import React, { useEffect, useState } from "react";

const ShopDetails = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const dispatch = useDispatch();
  const { condoData } = useSelector((state) => state.data);
  const { messageData, buttonRegister, errors } = useSelector(
    (state) => state.message
  );

  const [random, setRandom] = useState(false);

  useEffect(() => {
    dispatch(setProperty(condoData.propertyName));

    const randomNumber = Math.floor(Math.random() * 10) + 1;
    console.log(randomNumber);
    if (randomNumber % 2 === 0) setRandom(true);
  }, []);

  useEffect(() => {
    return () => dispatch(setButtonRegister("SEND"));
  }, []);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePhone = (phone) => {
    const re = /^(1\s?)?(\([0-9]{3}\)|[0-9]{3})[-. ]?[0-9]{3}[-. ]?[0-9]{4}$/;
    return re.test(phone);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!messageData.name) newErrors.name = "*Full name required";
    if (!messageData.email) newErrors.email = "*Email required";
    if (!validateEmail(messageData.email))
      newErrors.email = "*Invalid email format";
    if (!messageData.phoneNumber) newErrors.phoneNumber = "*required";
    if (!validatePhone(messageData.phoneNumber))
      newErrors.phoneNumber = "*Accepted format (xxx) xxx-xxxx";
    if (!messageData.realtor) newErrors.realtor = "*Yes or No prompt required";

    dispatch(setErrors(newErrors));
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      console.log("Validated!");

      const date = new Date();

      fetch(
        "https://9gf4bi3vx1.execute-api.us-west-2.amazonaws.com/sendEmail",
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            senderName: "presalegreatervan@gmail.com",
            // senderName: "ethanlloyd21@gmail.com",
            senderEmail: "presalegreatervan@gmail.com",
            message: `<p><b>Name: </b> ${messageData.name}</p>
          <p><b>Email: </b>${messageData.email}</p>
          <p><b>Phone Number: </b>${messageData.phoneNumber} </p>
          <p><b>Property: </b>${messageData.property}</p>
          <p><b>Realtor: </b>${messageData.realtor}</p>
          <p><b>Message: </b> </p>
          <p>${messageData.message}</p>
          <p><b>Date: ${date.toDateString()} </p>`,
            date: date.toString(),
            subject: "Register: ",
            name: messageData.name,
            email: messageData.email,
          }),
        }
      );

      dispatch(setReset());
      dispatch(setButtonRegister("Submited"));
    }
  };

  return (
    <div className="ltn__shop-details-area pb-10">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
              <div className="ltn__blog-meta">
                <ul>
                  <li className="ltn__blog-category">
                    <Link to="#">
                      <span style={{ color: "white" }}>Featured</span>
                    </Link>
                  </li>
                  <li className="ltn__blog-category">
                    <Link className="bg-orange" to="#">
                      Pre Sale
                    </Link>
                  </li>
                  <li className="ltn__blog-date">
                    <i className="far fa-calendar-alt" />
                    {condoData.details.estimatedCompletion}
                  </li>
                  {/* <li>
                    <Link to="#">
                      <i className="far fa-comments" />
                      35 Comments
                    </Link>
                  </li> */}
                </ul>
              </div>
              <h1>{condoData.propertyName}</h1>
              <label>
                <span className="ltn__secondary-color">
                  <i className="flaticon-pin" />
                </span>{" "}
                {condoData.address}
              </label>

              {/* <div className="ltn__feature-info">
                <button className="btn theme-btn-1">
                  <a
                    href={condoData.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      WEBSITE LINK <i className="flaticon-right-arrow" />
                    </span>
                  </a>
                </button>
              </div> */}

              <h4 className="title-2">Information</h4>
              <div className="row">
                <div className="col-sm-12 col-md-6 ">
                  <div>{condoData.information}</div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div>
                    <b>Building Type: </b>
                    {condoData.details.buildingType}
                  </div>
                  <div>
                    <b>Assignment Fee: </b>
                    {condoData.details.assignmentFee}
                  </div>
                  <div>
                    <b>Strata Fee: </b>${condoData.details.strataFee}
                  </div>
                  <div>
                    <b>Deposit Structure: </b>
                    <ul>
                      {condoData.details.depositStructure.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <h4 className="title-2">Property Details</h4>
              <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                <ul>
                  <li>
                    <label>Developer:</label> <span>{condoData.developer}</span>
                  </li>
                  <li>
                    <label>City:</label> <span>{condoData.city}</span>
                  </li>
                  <li>
                    <label>Floor Space: </label>{" "}
                    <span>{condoData.details.floorspace} sq ft</span>
                  </li>
                  <li>
                    <label>Beds:</label>{" "}
                    <span>{condoData.details.bedrooms}</span>
                  </li>
                  <li>
                    <label>Units:</label>{" "}
                    <span>{condoData.details.units} </span>
                  </li>
                  <li>
                    <label>Stories:</label>{" "}
                    <span>{condoData.details.stories}</span>
                  </li>
                </ul>
                <ul>
                  <li>
                    <label>Walk Score:</label>{" "}
                    <span>{condoData.scores.walkScore.score} out of 100</span>
                  </li>
                  <li>
                    <label>Comments:</label>{" "}
                    <span>{condoData.scores.walkScore.description}</span>
                  </li>
                  <li>
                    <label>Bike Score:</label>{" "}
                    <span>
                      {condoData.scores.bikeScore.bikescore} out of 100
                    </span>
                  </li>
                  <li>
                    <label>Comments:</label>{" "}
                    <span>{condoData.scores.bikeScore.description}</span>
                  </li>
                  <li>
                    <label>Starting Price:</label>{" "}
                    <span>${condoData.startingPrice}</span>
                  </li>
                  <li>
                    <label>Completion:</label>{" "}
                    <span>{condoData.details.estimatedCompletion}</span>
                  </li>
                </ul>
              </div>

              <h4 className="title-2 mb-10">Amenities</h4>
              <div className="property-details-amenities mb-60">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="ltn__menu-widget">
                      <ul>
                        {condoData.amenities.map((item, index) => (
                          <li key={index}>
                            <label className="checkbox-item">
                              {item}
                              <input type="checkbox" defaultChecked="checked" />
                              <span className="checkmark" />
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {/* <div className="col-lg-4 col-md-6">
                    <div className="ltn__menu-widget">
                      <ul>
                        <li>
                          <label className="checkbox-item">
                            Barbeque
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            Recreation
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            Microwave
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            Basketball Cout
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            Fireplace
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="ltn__menu-widget">
                      <ul>
                        <li>
                          <label className="checkbox-item">
                            Refrigerator
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            Window Coverings
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            Washer
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            24x7 Security
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            Indoor Game
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                      </ul>
                    </div>
                      </div> */}
                </div>
              </div>
              <h4 className="title-2">Location</h4>
              <div className="property-details-google-map mb-60">
                <iframe
                  src={condoData.map}
                  width="100%"
                  height="100%"
                  frameBorder={0}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                  title="test"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
              {/* Author Widget */}

              <div className="widget ltn__author-widget">
                <h4 className="ltn__widget-title ltn__widget-title-border-2">
                  Sponsored by:
                </h4>
                {random ? (
                  <div className="ltn__author-widget-inner text-center">
                    <img src={"assets/img/condo/JPcrop.jpg"} alt="8" />
                    <h5>JP Villanueva</h5>
                    <small>
                      <b>Realtor | EXP Realty</b>
                    </small>
                    <p>
                      As an experienced Realtor, I'm dedicated to providing
                      personalized service, guiding you through your real estate
                      journey. Your needs are my priority, and I'm committed to
                      making them a reality.
                    </p>
                    <div className="ltn__social-media">
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/presalegreatervan?mibextid=ZbWKwL"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Facebook"
                          >
                            <i className="fab fa-facebook-f" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com"
                            title="Twitter"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fab fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://linkedin.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Linkedin"
                          >
                            <i className="fab fa-linkedin" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="ltn__author-widget-inner text-center">
                    <img src={"assets/img/condo/samuraiCondo.png"} alt="8" />
                    <h5>Serverless Samurai</h5>
                    <small>
                      <b>learn, develop, conquer</b>
                    </small>
                    <p>
                      Welcome to Serverless-Samurai.com, your definitive guide
                      to crafting serverless applications on AWS. Tailored for
                      both beginners and seasoned developers, our tutorials
                      demystify cloud-native solutions, empowering you to learn
                      and develop innovative applications with ease and
                      confidence. Visit us at <a href="https://serverless-samurai.com" rel="noopener noreferrer" target="_blank">www.serverless-samurai.com</a>
                    </p>
                    <div className="ltn__social-media">
                      <ul>
                        <li>
                          <a
                            href="https://www.serverless-samurai.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Facebook"
                          >
                            <i className="fab fa-facebook-f" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.serverless-samurai.com/"
                            title="Twitter"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fab fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/serverless-samurai/?viewAsMember=true"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Linkedin"
                          >
                            <i className="fab fa-linkedin" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>

              {/* <div className="widget ltn__author-widget">
                <h4 className="ltn__widget-title ltn__widget-title-border-2">
                  Sponsored by:
                </h4>
                <div className="ltn__author-widget-inner text-center">
                  <img src={"assets/img/condo/JPcrop.jpg"} alt="8" />
                  <h5>JP Villanueva</h5>
                  <small>
                    <b>Realtor | EXP Realty</b>
                  </small>
                  <p>
                    As an experienced Realtor, I'm dedicated to providing
                    personalized service, guiding you through your real estate
                    journey. Your needs are my priority, and I'm committed to
                    making them a reality.
                  </p>
                  <div className="ltn__social-media">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/presalegreatervan?mibextid=ZbWKwL"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Facebook"
                        >
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com"
                          title="Twitter"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://linkedin.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Linkedin"
                        >
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
              {/* Search Widget */}
              {/* <div className="widget ltn__search-widget">
                <h4 className="ltn__widget-title ltn__widget-title-border-2">
                  Search Objects
                </h4>
                <form action="#">
                  <input
                    type="text"
                    name="search"
                    placeholder="Search your keyword..."
                  />
                  <button type="submit">
                    <i className="fas fa-search" />
                  </button>
                </form>
              </div> */}
              {/* Form Widget */}
              <div className="widget ltn__form-widget">
                <h4 className="ltn__widget-title ltn__widget-title-border-2">
                  Register to this property
                </h4>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Your Name*"
                    value={messageData.name}
                    onChange={(e) => dispatch(setName(e.target.value))}
                  />
                  {errors.name && (
                    <p>
                      <span style={{ color: "#bc3928" }}>{errors.name}</span>
                    </p>
                  )}
                  <input
                    disabled
                    type="text"
                    name="property"
                    // placeholder="Do you need a Realtor?*"
                    defaultValue={"Property: " + condoData.propertyName}
                  />
                  <input
                    type="text"
                    name="email"
                    placeholder="Your e-Mail*"
                    value={messageData.email}
                    onChange={(e) => dispatch(setEmail(e.target.value))}
                  />
                  {errors.email && (
                    <p>
                      <span style={{ color: "#bc3928" }}>{errors.email}</span>
                    </p>
                  )}
                  <input
                    type="text"
                    name="phoneNumber"
                    placeholder="Your phone*"
                    value={messageData.phoneNumber}
                    onChange={(e) => dispatch(setPhoneNumber(e.target.value))}
                  />
                  {errors.phoneNumber && (
                    <p>
                      <span style={{ color: "#bc3928" }}>
                        {errors.phoneNumber}
                      </span>
                    </p>
                  )}
                  <input
                    type="text"
                    name="realtor"
                    placeholder="Do you need a Realtor?*"
                    value={messageData.realtor}
                    onChange={(e) => dispatch(setRealtor(e.target.value))}
                  />
                  {errors.realtor && (
                    <p>
                      <span style={{ color: "#bc3928" }}>{errors.realtor}</span>
                    </p>
                  )}
                  <textarea
                    name="yourmessage"
                    placeholder="Write Message..."
                    value={messageData.message}
                    onChange={(e) => dispatch(setMessage(e.target.value))}
                  />
                  <div style={{paddingTop: 40}}>
                  <button type="submit" className="btn theme-btn-1">
                    {buttonRegister}
                  </button>
                  </div>
                  
                </form>
              </div>
              {/* Top Rated Product Widget */}

              {/* Menu Widget (Category) */}

              {/* Popular Product Widget */}

              {/* Popular Post Widget */}

              {/* Social Media Widget */}
              <div className="widget ltn__social-media-widget">
                <h4 className="ltn__widget-title ltn__widget-title-border-2">
                  Follow us
                </h4>
                <div className="ltn__social-media-2">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/presalegreatervan?mibextid=ZbWKwL"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Facebook"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com"
                        title="Twitter"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://linkedin.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Linkedin"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Tagcloud Widget */}

              {/* Banner Widget */}
              <div className="widget ltn__banner-widget d-none go-top">
                <Link to="/shop">
                  <img src={"assets/img/banner/2.jpg"} alt="#" />
                </Link>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopDetails;
