import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import parse from "html-react-parser";

import { allCitiesData } from "../../prod/assets/data/data";
import ProductItem from "../../prod/components/shop-grid/ProductItem";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../redux/feature/dataSlice";

const Availability = () => {
  const [itemObject, setItemObject] = useState({});

  const dispatch = useDispatch();
  const { getData } = useSelector((state) => state.getData);
  console.log(getData.Items);

  return (
    <div className="select-availability-area pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center---">
              <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                Avialable Spaces
              </h6>
              <h1 className="section-title">Projects Preview</h1>
            </div>
          </div>
        </div>
        <div className="ltn__product-tab-content-inner ltn__product-grid-view">
          <div className="row">
            <div className="col-lg-12">
              {/* Search Widget */}
              {/* <div className="ltn__search-widget mb-30">
              <form action="#">
                <input
                  type="text"
                  name="search"
                  placeholder="Search your keyword..."
                />
                <button type="submit">
                  <i className="fas fa-search" />
                </button>
              </form>
            </div> */}
            </div>
            {/* ltn__product-item */}

            {getData?.Items?.slice(getData.Items.length - 6, getData.Items.length).map((item, index) => (
              <div className="col-lg-4 col-sm-6 col-12" key={index} onClick={() => dispatch(setData(item))}>
                <ProductItem item={item} setItemObject={setItemObject} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    // <div className="select-availability-area pb-120">
    //   <div className="container">
    //     <div className="row">
    //       <div className="col-lg-12">
    //         <div className="section-title-area ltn__section-title-2--- text-center---">
    //           <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
    //             Avialable Spaces
    //           </h6>
    //           <h1 className="section-title">Select Availability</h1>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="row">
    //       <div className="col-lg-12">
    //         <div className="ltn__select-availability-table-wrap">
    //           <div className="ltn__select-availability-table d-none d-md-block">
    //             <ul className="ltn__select-availability-table-head">
    //               <li>Residence</li>
    //               <li>Type</li>

    //               <li>City</li>
    //               <li>Price</li>
    //               <li>Details</li>
    //             </ul>
    //             {allCitiesData.map((item, index) => (
    //               <ul
    //                 className="ltn__select-availability-table-row"
    //                 key={index}
    //               >
    //                 <li className="tower-name">{item.propertyName}</li>
    //                 <li>{item.details.buildingType}</li>

    //                 <li>{item.city} </li>
    //                 <li>{item.startingPrice}+</li>
    //                 <li>+ Available</li>
    //               </ul>
    //             ))}

    //           </div>
    //           <div className="ltn__select-availability-table-responsive d-md-none">
    //             {allCitiesData.map((item, index) => (
    //               <ul
    //                 className="ltn__select-availability-table-row-responsive-item"
    //                 key={index}
    //               >
    //                 <li>
    //                   <span>Residence</span>{" "}
    //                   <span className="tower-name">{item.propertyName}</span>
    //                 </li>

    //                 <li>
    //                   <span>Type</span>
    //                   <span>{item.details.buildingType} </span>
    //                 </li>
    //                 <li>
    //                   <span>City</span>
    //                   <span>{item.city} </span>
    //                 </li>

    //                 <li>
    //                   <span>Price</span>
    //                   <span>{item.startingPrice}+</span>
    //                 </li>

    //                 <li>
    //                   <span>Details</span>
    //                   <span>{item.startingPrice === "TBD" ? "+Register" :  "+Available"}</span>
    //                 </li>
    //               </ul>
    //             ))}

    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Availability;
