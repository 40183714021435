import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { allCitiesData } from "../../prod/assets/data/data";
import ProductItem from "../../prod/components/shop-grid/ProductItem";
import ProductItem2 from "../../prod/components/shop-grid/ProductItem2";
import { useSelector, useDispatch } from "react-redux";
import { setData } from "../../redux/feature/dataSlice";
import ModalComponent from "../../components/shop-components/shop-details";
import { setGetData } from "../../redux/feature/getDataSlice";
import { Select, Form } from "antd";

import "antd/dist/antd.css";
import "../../css/app.css";

const { Option } = Select;

function ShopGridV1() {
  const dispatch = useDispatch();
  const { getData } = useSelector((state) => state.getData);
  console.log(getData);
  const { condoData } = useSelector((state) => state.data);

  const [itemObject, setItemObject] = useState({});
  // console.log(itemObject);

  // useEffect(() => {
  //   axios
  //     .get("https://hyonlxjq70.execute-api.us-west-2.amazonaws.com/dev/items/")
  //     .then((response) => {
  //       dispatch(setGetData(response.data.Items))
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  const [isModalOpen, setModalOpen] = useState(false);
  const [cityItem, setCityItem] = useState("ALL");
  console.log(cityItem);

  const handleChange = (event) => {
    event.prevent.deafult();
    setCityItem(event.target.value);
    console.log("triggered", event);
  };

  const handleChangeAntDesign = (value) => {
   
    setCityItem(value);
    console.log("triggered", value)
  };

  const handleOpen = (item) => {
    dispatch(setData(item));
    setModalOpen(true);
  };
  const handleClose = () => setModalOpen(false);

  return (
    <div>
      <div className="ltn__product-area ltn__product-gutter mb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__shop-options">
                <ul>
                  <li>
                    <div className="ltn__grid-list-tab-menu ">
                      <div className="nav">
                        <a
                          className="active show"
                          data-bs-toggle="tab"
                          href="#liton_product_grid"
                        >
                          <span style={{ color: "#ff5a3c" }}>
                            <i className="fas fa-th-large" />
                          </span>
                        </a>
                        <a data-bs-toggle="tab" href="#liton_product_list">
                          <i className="fas fa-list" />
                        </a>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="showing-product-number text-right">
                      <span>
                        Showing {getData.length} of {getData.length} results
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="tab-content ">
                <div
                  className="tab-pane fade active show"
                  id="liton_product_grid"
                >
                  <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                    <div className="row">
                      <div className="col-lg-12">
                        {/* Search Widget */}
                        {/* <div className="ltn__search-widget mb-30">
                            <form action="#">
                              <input
                                type="text"
                                name="search"
                                placeholder="Search your keyword..."
                              />
                              <button type="submit">
                                <i className="fas fa-search" />
                              </button>
                            </form>
                          </div> */}
                        {/* <h6>Country</h6> */}
                        {/* <div className="input-item">
                          <select
                            className="custome-select-css"
                            value={cityItem}
                            placeholder="Select a city"
                            onChange={(e) => setCityItem(e.target.value)}
                       
                          >
                            <option value="View All">
                              View All
                            </option>
                            <option value="Surrey">Surrey</option>
                            <option value="Vancouver">Vancouver</option>
                            <option value="Richmond">Richmond</option>
                            <option value="Burnaby">Burnaby</option>
                          </select>
                        </div> */}
                      </div>

                      {/* <div className="input-item"> */}
                     
                        <Select
                          placeholder="Select a option and change input text above"
                          onChange={handleChangeAntDesign}
                          value={cityItem}
                          size="large"
                          options={[
                            {
                              value: "ALL",
                              label: "ALL"
                            }, 
                            {
                              value: "Surrey",
                              label: "Surrey"
                            },
                            {
                              value: "Vancouver",
                              label: "Vancouver"
                            },
                            {
                              value: "Richmond",
                              label: "Richmond"
                            },
                            {
                              value: "Burnaby",
                              label: "Burnaby"
                            },
                            {
                              value: "Coquitiam",
                              label: "Coquitiam"
                            },
                            {
                              value: "Port Moody",
                              label: "Port Moody"
                            },
                            {
                              value: "Langley",
                              label: "Langley"
                            },
                            {
                              value: "Abbotsford",
                              label: "Abbotsford"
                            },
                            {
                              value: "Maple Ridge",
                              label: "Maple Ridge"
                            }

                          ]}
                        >
                          
                        </Select>
                       
                      {/* </div> */}

                      {/* ltn__product-item */}

                      {
                        cityItem === "ALL" ? 
                          getData.map((item) => (
                            <div
                              className="col-lg-4 col-sm-6 col-12"
                              key={item.id}
                              onClick={() => dispatch(setData(item))}
                              // onClick={() => handleOpen(item)}
                              // style={{ cursor: "pointer" }}
                              // data-bs-toggle="modal"
                              // data-bs-target="#quick_view_modal"
                              style={{paddingTop: 40}}
                            >
                              <ProductItem
                                item={item}
                                setItemObject={setItemObject}
                              />
                            </div>
                          ))
                        :  getData.filter((item) => item.city === cityItem).map((item) => (
                          <div
                            className="col-lg-4 col-sm-6 col-12"
                            key={item.id}
                            onClick={() => dispatch(setData(item))}
                            // onClick={() => handleOpen(item)}
                            // style={{ cursor: "pointer" }}
                            // data-bs-toggle="modal"
                            // data-bs-target="#quick_view_modal"
                            style={{paddingTop: 40}}
                          >
                            <ProductItem
                              item={item}
                              setItemObject={setItemObject}
                            />
                          </div>
                        ))
                      }
                      
                      {/* {getData.filter((item) => item.city === cityItem).map((item) => (
                        <div
                          className="col-lg-4 col-sm-6 col-12"
                          key={item.id}
                          onClick={() => dispatch(setData(item))}
                          
                          style={{paddingTop: 40}}
                        >
                          <ProductItem
                            item={item}
                            setItemObject={setItemObject}
                          />
                        </div>
                      ))} */}

                      {/** */}
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="liton_product_list">
                  <div className="ltn__product-tab-content-inner ltn__product-list-view">
                    <div className="row">
                      <div className="col-lg-12">
                        {/* Search Widget */}
                        {/* <div className="ltn__search-widget mb-30">
                            <form action="#">
                              <input
                                type="text"
                                name="search"
                                placeholder="Search your keyword..."
                              />
                              <button type="submit">
                                <i className="fas fa-search" />
                              </button>
                            </form>
                          </div> */}
                      </div>
                      {/* ltn__product-item */}
                      {getData.map((item, index) => (
                        <div
                          className="col-lg-12"
                          key={index}
                          onClick={() => dispatch(setData(item))}
                          style={{ cursor: "pointer" }}
                        >
                          <ProductItem2
                            item={item}
                            setItemObject={setItemObject}
                          />
                        </div>
                      ))}

                      {/*  */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ltn__modal-area ltn__quick-view-modal-area">
        <div className="modal fade" id="quick_view_modal" tabIndex={-1}>
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="ltn__quick-view-modal-inner">
                  <ModalComponent />
                  {/* <div className="modal-product-item">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <div className="modal-product-img">
                          <img src={"assets/img/product/4.png"} alt="#" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="modal-product-info">
                          <div className="product-ratting">
                            <ul>
                              <li>
                                <a href="#">
                                  <i className="fas fa-star" />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fas fa-star" />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fas fa-star" />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fas fa-star-half-alt" />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="far fa-star" />
                                </a>
                              </li>
                              <li className="review-total">
                                {" "}
                                <a href="#"> ( 95 Reviews )</a>
                              </li>
                            </ul>
                          </div>
                          <h3>Brake Conversion Kit</h3>
                          <div className="product-price">
                            <span>$149.00</span>
                            <del>$165.00</del>
                          </div>
                          <div className="modal-product-meta ltn__product-details-menu-1">
                            <ul>
                              <li>
                                <strong>Categories:</strong>
                                <span className="go-top">
                                  <Link to="/blog">Parts</Link>
                                  <Link to="/blog">Car</Link>
                                  <Link to="/blog">Seat</Link>
                                  <Link to="/blog">Cover</Link>
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="ltn__product-details-menu-2">
                            <ul>
                              <li>
                                <div className="cart-plus-minus">
                                  <input
                                    type="text"
                                    defaultValue="02"
                                    name="qtybutton"
                                    className="cart-plus-minus-box"
                                  />
                                </div>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  className="theme-btn-1 btn btn-effect-1"
                                  title="Add to Cart"
                                  data-bs-toggle="modal"
                                  data-bs-target="#add_to_cart_modal"
                                >
                                  <i className="fas fa-shopping-cart" />
                                  <span>ADD TO CART</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <hr />
                          <div className="ltn__social-media">
                            <ul>
                              <li>Share:</li>
                              <li>
                                <a href="#" title="Facebook">
                                  <i className="fab fa-facebook-f" />
                                </a>
                              </li>
                              <li>
                                <a href="#" title="Twitter">
                                  <i className="fab fa-twitter" />
                                </a>
                              </li>
                              <li>
                                <a href="#" title="Linkedin">
                                  <i className="fab fa-linkedin" />
                                </a>
                              </li>
                              <li>
                                <a href="#" title="Instagram">
                                  <i className="fab fa-instagram" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/**Modal */}
      <div className="ltn__modal-area ltn__add-to-cart-modal-area">
        <div className="modal fade" id="add_to_cart_modal" tabIndex={-1}>
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <ModalComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopGridV1;
