import React from "react";

const TestimonialMain = () => {
  return (
    <div
      className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70"
      data-bs-bg={"assets/img/bg/20.jpg"}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                Our Testimonial
              </h6>
              <h1 className="section-title">Clients Feedback</h1>
            </div>
          </div>
        </div>

        <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
          <div className="col-lg-4">
            <div className="ltn__testimonial-item ltn__testimonial-item-7">
              <div className="ltn__testimoni-info">
                <p>
                  <i className="flaticon-left-quote-1" />
                  The location of the condo couldn't be better. It's close to
                  amenities, shops, and public transit, making it not just a
                  home but a lifestyle upgrade. Thank you Pre-Sale Condo BC for
                  you help. I highly recommend this company.
                  
                </p>
                <div className="ltn__testimoni-info-inner">
                  <div className="ltn__testimoni-img">
                    <img src={"assets/img/testimonial/cleveland.jpg"} alt="#" />
                  </div>
                  <div className="ltn__testimoni-name-designation">
                    <h5>Patrick Gagne</h5>
                    <label>Home Owner</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__testimonial-item ltn__testimonial-item-7">
              <div className="ltn__testimoni-info">
                <p>
                  <i className="flaticon-left-quote-1" />
                  I've recently had the pleasure of purchasing a pre-sale condo
                  through this esteemed company, and I must say, it has been an
                  exceptional experience. The professionalism, care, and
                  diligence demonstrated by the team were beyond impressive.
                </p>
                <div className="ltn__testimoni-info-inner">
                  <div className="ltn__testimoni-img">
                    <img src={"assets/img/testimonial/cleveland2.jpg"} alt="#" />
                  </div>
                  <div className="ltn__testimoni-name-designation">
                    <h5>John Cleveland</h5>
                    <label>Home Owner</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__testimonial-item ltn__testimonial-item-7">
              <div className="ltn__testimoni-info">
                <p>
                  <i className="flaticon-left-quote-1" />
                  I was provided with clear information about
                  the property, which was crucial in aiding my decision to
                  purchase. The team's expert knowledge of the real estate
                  market was evident and instilled confidence in me.
                </p>
                <div className="ltn__testimoni-info-inner">
                  <div className="ltn__testimoni-img">
                    <img src={"assets/img/testimonial/david2.jpg"} alt="#" />
                  </div>
                  <div className="ltn__testimoni-name-designation">
                    <h5>David Roy</h5>
                    <label>Home Owner</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__testimonial-item ltn__testimonial-item-7">
              <div className="ltn__testimoni-info">
                <p>
                  <i className="flaticon-left-quote-1" />I wholeheartedly
                  recommend this company to anyone considering buying a pre-sale
                  condo. Their unmatched professionalism, coupled with their
                  dedication to their clients, make them the ideal choice. I'm
                  thrilled with my new home."
                </p>
                <div className="ltn__testimoni-info-inner">
                  <div className="ltn__testimoni-img">
                    <img src={"assets/img/testimonial/hassan2.jpg"} alt="#" />
                  </div>
                  <div className="ltn__testimoni-name-designation">
                    <h5>Tarik Hassan</h5>
                    <label>Home Owner</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__testimonial-item ltn__testimonial-item-7">
              <div className="ltn__testimoni-info">
                <p>
                  <i className="flaticon-left-quote-1" />
                  Now that I've moved into my new home, I can say with
                  confidence that the quality of construction and attention to
                  detail have exceeded my expectations. It's clear that every
                  aspect was designed with care and craftsmanship.
                </p>
                <div className="ltn__testimoni-info-inner">
                  <div className="ltn__testimoni-img">
                    <img src={"assets/img/testimonial/felicia.jpg"} alt="#" />
                  </div>
                  <div className="ltn__testimoni-name-designation">
                    <h5>Felicia Dela Cruz</h5>
                    <label>Home Owner</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__testimonial-item ltn__testimonial-item-7">
              <div className="ltn__testimoni-info">
                <p>
                  <i className="flaticon-left-quote-1" />
                  Investing in a pre-sale condo was a financially sound
                  decision. I secured my unit at a competitive price, and its
                  value has already appreciated significantly even before moving
                  in. This gave me a head-start in building equity.
                </p>
                <div className="ltn__testimoni-info-inner">
                  <div className="ltn__testimoni-img">
                    <img src={"assets/img/testimonial/uy.jpg"} alt="#" />
                  </div>
                  <div className="ltn__testimoni-name-designation">
                    <h5>Richard Uy</h5>
                    <label>Home Owner</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__testimonial-item ltn__testimonial-item-7">
              <div className="ltn__testimoni-info">
                <p>
                  <i className="flaticon-left-quote-1" />I highly recommend
                  considering a Pre-Sale Condo BC for anyone looking for a
                  customizable, rewarding, and strategic real estate investment.
                  It's a decision I am proud of and one I'd make again in a
                  heartbeat.
                </p>
                <div className="ltn__testimoni-info-inner">
                  <div className="ltn__testimoni-img">
                    <img src={"assets/img/testimonial/estabillo.jpg"} alt="#" />
                  </div>
                  <div className="ltn__testimoni-name-designation">
                    <h5>James Estabillo</h5>
                    <label>Home Owner</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </div>
    </div>
  );
};

export default TestimonialMain;
