import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setButtonRegister,
  setEmail,
  setErrorEmail,
  setErrorMessage,
  setErrorName,
  setErrorPhoneNumber,
  setMessage,
  setName,
  setPhoneNumber,
  setRealtor,
  setReset,
  setErrors,
} from "../../redux/feature/messageSlice";

const PreRegister = () => {
  console.log(useSelector((state) => console.log(state.message)));
  const dispatch = useDispatch();
  const { messageData, buttonRegister, errors } = useSelector(
    (state) => state.message
  );

  useEffect(() => {
    return () => dispatch(setButtonRegister("SEND"));
  }, [])


  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePhone = (phone) => {
    const re = /^(1\s?)?(\([0-9]{3}\)|[0-9]{3})[-. ]?[0-9]{3}[-. ]?[0-9]{4}$/;
    return re.test(phone);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!messageData.name) newErrors.name = "*required";
    if (!messageData.email) newErrors.email = "*required";
    if (!validateEmail(messageData.email)) newErrors.email = "*Invalid email format";
    if (!messageData.phoneNumber) newErrors.phoneNumber = "*required";
    if (!validatePhone(messageData.phoneNumber)) newErrors.phoneNumber = "*Accepted format (xxx) xxx-xxxx";
    if (!messageData.message) newErrors.message = "*required";
    if (!messageData.realtor) newErrors.realtor = "*Yes or No"


    dispatch(setErrors(newErrors));
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      console.log("Validated!");

      const date = new Date();

      fetch(
        "https://9gf4bi3vx1.execute-api.us-west-2.amazonaws.com/sendEmail",
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            senderName: "presalegreatervan@gmail.com",
            // senderName: "ethanlloyd21@gmail.com",
            senderEmail: "presalegreatervan@gmail.com",
            message: `<p><b>Name: </b> ${messageData.name}</p>
          <p><b>Email: </b>${messageData.email}</p>
          <p><b>Phone Number: </b>${messageData.phoneNumber} </p>
          <p><b>Realtor: </b>${messageData.realtor}</p>
          <p><b>Property: </b> </p>
          <p>${messageData.message}</p>
          <p><b>Date: ${date.toDateString()} </p>`,
            date: date.toString(),
            subject: "Pre-register:",
            name: messageData.name,
            email: messageData.email,
          }),
        }
      );

      dispatch(setReset());

      dispatch(setButtonRegister("Submited Successful!"));
    }
  };

  return (
    <div className="ltn__login-area pb-110">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area text-center">
              <h1 className="section-title">
                Registration <br />
                Form
              </h1>
              <p>
                Discover a world of luxury living. Pre-register now for our
                exclusive condo presale and secure your dream home today.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="account-login-inner">
              <form onSubmit={handleSubmit}>
                <div className="ltn__form-box contact-form-box">
                  <input
                    error={!!errors.name}
                    type="text"
                    name="name"
                    placeholder="Name*"
                    value={messageData.name}
                    onChange={(e) => {
                      dispatch(setName(e.target.value));
                      dispatch(setErrorName(""));
                    }}
                  />
                  {errors.name && (
                    <p>
                      <span style={{ color: "#bc3928" }}>{errors.name}</span>
                    </p>
                  )}
                  <input
                    error={!!errors.email}
                    type="email"
                    name="email"
                    placeholder="Email*"
                    value={messageData.email}
                    onChange={(e) => {
                      dispatch(setEmail(e.target.value));
                      dispatch(setErrorEmail(""));
                    }}
                  />
                  {errors.email && (
                    <p>
                      <span style={{ color: "#bc3928" }}>{errors.email}</span>
                    </p>
                  )}
                  <input
                    error={!!errors.phoneNumber}
                    type="text"
                    name="phone"
                    placeholder="Phone*"
                    value={messageData.phoneNumber}
                    onChange={(e) => {
                      dispatch(setPhoneNumber(e.target.value));
                      dispatch(setErrorPhoneNumber(""));
                    }}
                  />
                  {errors.phoneNumber && (
                    <p>
                      <span
                        style={{ color: "#bc3928" }}
                        className="by-agree text-center"
                      >
                        {errors.phoneNumber}
                      </span>
                    </p>
                  )}
                    <input
                    error={!!errors.realtor}
                    type="text"
                    name="realtor"
                    placeholder="Do you have a Realtor? Yes/No*"
                    value={messageData.realtor}
                    onChange={(e) => {
                      dispatch(setRealtor(e.target.value));
                      
                    }}
                  />
                  {errors.realtor && (
                    <p>
                      <span
                        style={{ color: "#bc3928" }}
                        className="by-agree text-center"
                      >
                        {errors.realtor}
                      </span>
                    </p>
                  )}
                  <textarea
                    
                    name="message"
                    placeholder="Let us know how can we help. Please include the property name you want to register to*"
                    value={messageData.message}
                    onChange={(e) => {
                      dispatch(setMessage(e.target.value));
                      dispatch(setErrorMessage(""));
                    }}
                  />
                  {errors.message && (
                    <p>
                      <span
                        style={{ color: "#bc3928" }}
                        className="by-agree text-center"
                      >
                        {errors.message}
                      </span>
                    </p>
                  )}
                  {/* <label className="checkbox-inline">
                  <input type="checkbox" defaultValue />
                  &nbsp; I consent to Herboil processing my personal data in
                  order to send personalized marketing material in accordance
                  with the consent form and the privacy policy.
                </label> */}
                  <label className="checkbox-inline">
                    <input type="checkbox" defaultValue /> &nbsp; By clicking I
                    consent to the privacy policy.
                  </label>
                  <div className="btn-wrapper">
                    <button
                      className="theme-btn-1 btn reverse-color btn-block"
                      type="submit"
                    >
                      {buttonRegister}
                    </button>
                  </div>
                </div>
              </form>
              <div className="by-agree text-center">
                <p>By pre-registering, you agree to our:</p>
                <p>
                  TERMS OF CONDITIONS &nbsp; &nbsp; | &nbsp; &nbsp; PRIVACY
                  POLICY
                </p>
                {/* <div className="go-to-btn mt-50 go-top">
                  <Link to="/login">ALREADY HAVE AN ACCOUNT ?</Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreRegister;
