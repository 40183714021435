import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class BannerV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <div className="ltn__slider-area ltn__slider-11  ltn__slider-11-slide-item-count-show--- ltn__slider-11-pagination-count-show--- section-bg-1">
			  <div className="ltn__slider-11-inner">
			    <div className="ltn__slider-11-active">
			      {/* slide-item */}
			      <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
			        <div className="ltn__slide-item-inner">
			          <div className="container">
			            <div className="row">
			              <div className="col-lg-12 align-self-center">
			                <div className="slide-item-info">
			                  <div className="slide-item-info-inner ltn__slide-animation">
			                    <div className="slide-video mb-50 d-none">
			                      <a className="ltn__video-icon-2 ltn__video-icon-2-border" href="https://www.youtube.com/embed/tlThdr3O5Qo" data-rel="lightcase:myCollection">
			                        <i className="fa fa-play" />
			                      </a>
			                    </div>
			                    <h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-home" /></span> Join us and be part of the story of </h6>
			                    <h1 className="slide-title animated ">Innovation <br /><span>Luxury</span> and Comfort</h1>
			                    <div className="slide-brief animated">
			                      <p>your dream home is just a click away. We are your gateway to the lifestyle of your dreams. </p>
			                    </div>
			                    <div className="btn-wrapper animated">
			                      <Link to="/register" className="theme-btn-1 btn btn-effect-1">Register</Link>
			                      {/* <a className="ltn__video-play-btn bg-white" href="https://www.youtube.com/embed/HnbMYzdjuBs?autoplay=1&showinfo=0" data-rel="lightcase">
			                        <i className="icon-play  ltn__secondary-color" />
			                      </a> */}
			                    </div>
			                  </div>
			                </div>
			                <div className="slide-item-img">
			                  <img src={"assets/img/condo/piano.png"} alt="#" />
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      {/* slide-item */}
			      <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
			        <div className="ltn__slide-item-inner">
			          <div className="container">
			            <div className="row">
			              <div className="col-lg-12 align-self-center">
			                <div className="slide-item-info">
			                  <div className="slide-item-info-inner ltn__slide-animation">
			                    <div className="slide-video mb-50 d-none">
			                      <a className="ltn__video-icon-2 ltn__video-icon-2-border" href="https://www.youtube.com/embed/tlThdr3O5Qo" data-rel="lightcase:myCollection">
			                        <i className="fa fa-play" />
			                      </a>
			                    </div>
			                    <h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-home" /></span> Pre-register now!</h6>
			                    <h1 className="slide-title animated ">Find Your Dream <br /> House By Us</h1>
			                    <div className="slide-brief animated">
			                      <p>Be the first to learn about our spectacular new condominiums, each crafted with care and sophistication to elevate your living experience.</p>
			                    </div>
			                    <div className="btn-wrapper animated">
			                      <Link to="/shop-grid" className="theme-btn-1 btn btn-effect-1">PROJECTS</Link>
			                      <Link to="/vip" className="btn btn-transparent btn-effect-3">Join us!</Link>
			                    </div>
			                  </div>
			                </div>
			                <div className="slide-item-img">
			                  <img src={"assets/img/slider/indoor2.jpg"} alt="#" />
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      {/* slide-item */}
			      <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
			        <div className="ltn__slide-item-inner">
			          <div className="container">
			            <div className="row">
			              <div className="col-lg-12 align-self-center">
			                <div className="slide-item-info">
			                  <div className="slide-item-info-inner ltn__slide-animation">
			                    <div className="slide-video mb-50 d-none">
			                      <a className="ltn__video-icon-2 ltn__video-icon-2-border" href="https://www.youtube.com/embed/tlThdr3O5Qo" data-rel="lightcase:myCollection">
			                        <i className="fa fa-play" />
			                      </a>
			                    </div>
			                    <h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-home" /></span> Pre-register now!</h6>
			                    <h1 className="slide-title animated ">Welcome to your <br /> future home</h1>
			                    <div className="slide-brief animated">
			                      <p>If you're seeking exceptional, unique, and meticulously planned condos designed for your comfort, you're in the right place.</p>
			                    </div>
			                    <div className="btn-wrapper animated">
			                      <Link to="/shop-grid" className="theme-btn-1 btn btn-effect-1">Projects</Link>
			                      <Link to="/vip" className="btn btn-transparent btn-effect-3">Join us!</Link>
			                    </div>
			                  </div>
			                </div>
			                <div className="slide-item-img">
			                  <img src={"assets/img/slider/indoor.jpg"} alt="#" />
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      {/* slide-item */}
			      <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
			        <div className="ltn__slide-item-inner">
			          <div className="container">
			            <div className="row">
			              <div className="col-lg-12 align-self-center">
			                <div className="slide-item-info">
			                  <div className="slide-item-info-inner ltn__slide-animation">
			                    <div className="slide-video mb-50 d-none">
			                      <a className="ltn__video-icon-2 ltn__video-icon-2-border" href="https://www.youtube.com/embed/tlThdr3O5Qo" data-rel="lightcase:myCollection">
			                        <i className="fa fa-play" />
			                      </a>
			                    </div>
			                    <h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-home" /></span> Pre-register now!</h6>
			                    <h1 className="slide-title animated ">Welcome to the <br /> future of living</h1>
			                    <div className="slide-brief animated">
			                      <p>act now because home is not just a place, it's a feeling.</p>
			                    </div>
			                    <div className="btn-wrapper animated">
			                      <Link to="/shop-grid" className="theme-btn-1 btn btn-effect-1">Projects</Link>
			                      <Link to="/vip" className="btn btn-transparent btn-effect-3">Join us!</Link>
			                    </div>
			                  </div>
			                </div>
			                <div className="slide-item-img">
			                  <img src={"assets/img/slider/bedroom.jpg"} alt="#" />
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			    {/* slider-4-pagination */}
			    <div className="ltn__slider-11-pagination-count">
			      <span className="count" />
			      <span className="total" />
			    </div>
			    {/* slider-sticky-icon */}
			    <div className="slider-sticky-icon-2">
			      <ul>
			        <li><a href="https://www.facebook.com/presalegreatervan?mibextid=ZbWKwL" title="Facebook" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"/></a></li>
			        <li><a href="https://www.twitter.com" title="Twitter" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter" /></a></li>
			        <li><a href="https://www.linkedin.com" title="Linkedin" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin" /></a></li>
			      </ul>
			    </div>
			    {/* slider-4-img-slide-arrow */}
			    <div className="ltn__slider-11-img-slide-arrow">
			      <div className="ltn__slider-11-img-slide-arrow-inner">
			        <div className="ltn__slider-11-img-slide-arrow-active">
			          <div className="image-slide-item">
			            <img src={"assets/img/condo/piano.png"}  alt="Flower1" />
			          </div>
			          <div className="image-slide-item">
			            <img src={"assets/img/slider/indoor2.jpg"} alt="Flower2" />
			          </div>
			          <div className="image-slide-item">
			            <img src={"assets/img/slider/indoor.jpg"} alt="Flower3" />
			          </div>
			          <div className="image-slide-item">
			            <img src={"assets/img/slider/bedroom.jpg"} alt="Flower4" />
			          </div>
			        </div>
			        {/* slider-4-slide-item-count */}
			        <div className="ltn__slider-11-slide-item-count">
			          <span className="count" />
			          <span className="total" />
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

        }
}

export default BannerV2